import { ExpandMoreOutlined, RouteOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Switch, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectParsedToolbarData,
	selectShowPositions,
	selectShowRoute,
	selectShowRouteByPosition,
	selectShowTrackers,
	togglePosition,
	toggleRouteByPosition,
	toggleShowRoute,
	toggleTrackers
} from 'app/store/map/routeToolbarSlice';

function RouteToolbar() {
	const { t } = useTranslation('mapPage');
	const dispatch = useDispatch();
	const data = useSelector(selectParsedToolbarData);
	const isPositionVisible = useSelector(selectShowPositions);
	const isTrackersVisible = useSelector(selectShowTrackers);
	const isRouteByPositionVisible = useSelector(selectShowRouteByPosition);
	const isRouteVisible = useSelector(selectShowRoute);

	const handlePositions = (ev: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(togglePosition(ev.target.checked));
	};

	const handleTrackers = (ev: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(toggleTrackers(ev.target.checked));
	};

	const handleRouteByPosition = (ev: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(toggleRouteByPosition(ev.target.checked));
	};

	const handleRoute = (ev: React.ChangeEvent<HTMLInputElement>) => dispatch(toggleShowRoute(ev.target.checked));

	return (
		<Accordion
			sx={{
				background: 'transparent'
			}}
			disableGutters
			elevation={0}
			className="cancel"
		>
			<AccordionSummary
				expandIcon={<ExpandMoreOutlined />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{
					padding: 0,
					minHeight: 'inherit',
					'& .MuiAccordionSummary-content': {
						margin: 0
					}
				}}
			>
				<Stack spacing={1} direction="row">
					<RouteOutlined color="secondary" />
					<Typography noWrap width="150px" fontWeight={600}>
						{data.name}
					</Typography>
				</Stack>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: '8px 0 0 0' }}>
				<Stack direction="column" spacing={2}>
					<Stack spacing={1} direction="row">
						<Typography fontWeight="bold">{`${t('START')}:`}</Typography>
						<Typography>{data.startDate}</Typography>
					</Stack>
					<Stack spacing={1} direction="row">
						<Typography fontWeight="bold">{`${t('END')}:`}</Typography>
						<Typography>{data.endDate}</Typography>
					</Stack>
					<Stack spacing={1} direction="row">
						<Typography fontWeight="bold">{`${t('DISTANCE')}:`}</Typography>
						<Typography>{data.distance}</Typography>
					</Stack>
					<Stack spacing={1} direction="row">
						<Typography fontWeight="bold">{`${t('ROUTERIZED_DISTANCE')}:`}</Typography>
						<Typography>{data.routerizedDistance}</Typography>
					</Stack>
					<Stack spacing={1} direction="row" alignItems="center">
						<Typography fontWeight="bold">{t('ROUTERIZED_ROUTE')}</Typography>
						<Switch onChange={handleRoute} checked={isRouteVisible} size="small" />
					</Stack>
					<Stack spacing={1} direction="row" alignItems="center">
						<Typography fontWeight="bold">{t('ROUTE_POINT_BY_POINT')}</Typography>
						<Switch onChange={handleRouteByPosition} checked={isRouteByPositionVisible} size="small" />
					</Stack>
					<Stack spacing={1} direction="row" alignItems="center">
						<Typography fontWeight="bold">{t('SHOW_POSITIONS')}</Typography>
						<Switch onChange={handlePositions} checked={isPositionVisible} size="small" />
					</Stack>
					<Stack spacing={1} direction="row" alignItems="center">
						<Typography fontWeight="bold">{t('SHOW_TRACKERS')}</Typography>
						<Switch onChange={handleTrackers} checked={isTrackersVisible} size="small" />
					</Stack>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
}

export default memo(RouteToolbar);
