export default {
	COMPANY: 'Company',
	TRACKER: 'Tracker',
	TRACKER_TYPE: 'Tracker Type',
	STATUS: 'Last Status',
	CREATED_AT: 'Sent At',
	SENDED_BY: 'Sent By',
	UPDATED_AT: 'Last Update',
	LAST_STATUS_UPDATED: 'Last status update',
	TITLE: 'Blocking/Unblocking Report',
	VIEW_SCHEDULED_COMMAND: 'View Scheduled Command',
	VIEW_HISTORY_COMMAND: 'View Command History',
	COMMAND_TIMELINE: 'Command Timeline',
	COMMAND: 'Command',
	NO_TIMELINE: 'No timeline found for this command',
	CONFIG_TABLE: 'Configure table',
	SHOW_FILTERS: 'Show Filters',
	CLEAR_FILTERS: 'Clear filters',
	NO_DATA: 'No data found',
	NO_FILTERS: 'No filters applied',
  	NO_FILTERS_SUBTITLE: 'Select the filters you want to apply to see the results',
	CREATED_BY: 'Created By',
	START_DATE: 'Start Date',
	END_DATE: 'End Date',
	SEARCH: "Search",
	ERROR_SELECTED_COMPANY: 'Select at least one tracker or one created by',

	PENDING: 'Pending',
	QUEUED: 'Scheduled',
	'RE-QUEUED': 'Re-queued',
	SENT: 'Sent',
	CONFIRMED: 'Confirmed',
	CANCELED: 'Canceled',
	ERROR: 'Error',
	EXPIRED: 'Expired',
	SCHEDULING: 'Scheduling'
};
