const locale = {
	// Columnas
	NAME: 'Nombre',
	ACTIVE: 'Activo',
	INACTIVE: 'Inactivo',
	COMPANY: 'Empresa',
	FLEETS: 'Flotas',
	VEHICLES: 'Vehículos',
	BLACKLIST: 'Lista Negra',
	TO_HIGH_PRIORITY: 'Prioridad Alta',
	TO_LOW_PRIORITY: 'Prioridad Baja',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	UPDATED_AT: 'Actualizado en',
	CANCEL: 'Cancelar',
	BACK: 'Volver',
	FINISH: 'Finalizar',
	CONTINUE: 'Continuar',
	CONFIRM: 'Confirmar',
	UPDATE_ERROR: 'Error al actualizar configuraciones de monitoreo',
	CREATE_ERROR: 'Error al crear configuraciones de monitoreo',

	// Tabla
	NO_SETTINGS_FOUND: 'No se encontraron configuraciones',
	ROWS_PER_PAGE: 'Filas por página',
	OF: 'de',
	N_FLEETS: '{{n}} flotas',
	N_VEHICLES: '{{n}} vehículos',
	N_ITEMS: '{{n}} elementos',

	// Encabezado
	TITLE: 'Configuraciones',
	ADD_NEW_SETTING: 'Agregar nueva configuración',

	// Barra lateral
	SELECTED_ITEMS: '{{n}} elementos seleccionados',
	DELETE_MULTIPLE_INFO: 'Eliminar configuraciones de monitoreo',
	DELETE_MODAL_TITLE: 'Eliminar configuraciones de monitoreo',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro de que deseas eliminar estas configuraciones?',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	DELETE_ERROR: 'Error al eliminar configuraciones de monitoreo',
	DOWNLOAD_INFO: 'Descargar configuraciones de monitoreo',
	DOWNLOAD_ERROR: 'Error al descargar configuraciones de monitoreo',
	DELETE_SUCCESS: 'Configuraciones eliminado exitosamente',

	// DialogForm
	EDIT_MONITORING_SETTINGS: 'Editar configuración',
	MONITORING_SETTINGS: 'Configuración de Monitoreo',
	NAME_AND_COMPANY: 'Nombre y Empresa',
	TARGETS: 'Elementos Monitoreados',
	REVIEW: 'Revisar configuraciones',
	// DialogForm - name and company
	SELECT_COMPANY: 'Selecciona una empresa',
	SELECT_NAME_AND_COMPANY: 'Agrega un nombre y empresa para la configuración',
	// DialogForm - targets
	SELECT_TARGETS_HEADER: 'Selecciona los elementos que serán monitoreados',
	TARGET_FLEETS: 'Flotas',
	FLEETS_LIMIT_TEXT: '{{n}} flotas seleccionadas',
	TARGET_VEHICLES: 'Vehículos',
	VEHICLES_LIMIT_TEXT: '{{n}} vehículos seleccionados',
	HIGH: 'Alta',
	MEDIUM: 'Media',
	LOW: 'Baja',
	SHOW: 'Mostrar',
	PRIORITY: 'Prioridad',
	// DialogForm - review
	REVIEW_PAGE: 'Revisar configuraciones',

	// DialogView
	BLACKLISTED_ITEMS: 'Alertas en Lista Negra',
	PRIORITIES: 'Alertas',
	SENT_FROM: 'Enviado desde',
	FLEET: 'Flota',
	VEHICLE: 'Vehículo',
	DELETE: 'Eliminar configuración',
	EDIT: 'Editar configuración',
	NO_BLACKLISTED_ITEMS: 'No hay alertas en lista negra',
	SELECT_ALL: 'Selecionar Todos',
	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',
};

export default locale;
