import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useThemeMediaQuery } from '@fuse/hooks';
import { selectTrackerCardLayout } from 'app/store/company/companySettingsSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch } from 'app/store/index';
import {
	selectMapInstance,
	selectSelectedTrackerId,
	setSelectedTrackerId,
	setTrackersOpen
} from 'app/store/map/mapSlice';
import { getTrackerClass, getTrackerDisplayName, isInvalidCoords } from '../../mapHelpers';
import { TTracker, TTrackerClass } from '../../types';
import DefaultLayout from './DefaultLayout';
import { GpsErrorPopup } from './GpsErrorPopup';
import SecondaryLayout from './SecondaryLayout';
import TertiaryLayout from './TertiaryLayout';

interface IDynamicCardsProps {
	tracker: TTracker;
}

export interface ITrackerCardProps {
	tracker: TTracker;
	isDarkMode: boolean;
	tooltipText: string;
	trackerClass: TTrackerClass;
	handleCardClick: () => void;
	parsedDates: ReturnType<typeof formatGpsTimeDate>;
	t: TFunction;
	selected: boolean;
	displayName: string;
}

export const formatGpsTimeDate = (tracker: TTracker) => {
	const gpsTime = _.get(tracker, 'packet.GPS_TIME', false);
	if (!gpsTime) return false;
	return {
		time: dayjs(gpsTime).format('HH:mm:ss'),
		date: dayjs(gpsTime).format('DD/MM/YYYY')
	};
};

function DynamicCards({ tracker }: IDynamicCardsProps) {
	const { t } = useTranslation('mapPage');
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const map = useSelector(selectMapInstance);
	const selectedCardVariant = useSelector(selectTrackerCardLayout);
	const selectedTrackerId = useSelector(selectSelectedTrackerId);
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('md'));
	const isSelected = selectedTrackerId === tracker._id;
	const isDarkMode = theme.palette.mode === 'dark';
	const trackerClass = getTrackerClass(tracker);
	const parsedDates = formatGpsTimeDate(tracker);
	const displayName = getTrackerDisplayName(tracker);

	const tooltipText = useMemo(
		() =>
			({
				'gps-error': t('WRONG_POSITION'),
				'moving-cars': t('MOVING'),
				'not-connected-cars': t('NO_COMM'),
				'stopped-cars': t('IDLE'),
				'towed-cars': t('TOWED'),
				'without-vehicle': t('WITHOUT_VEHICLE'),
				blocked: t('BLOCKED')
			}[trackerClass]),
		[trackerClass, t]
	);

	const handleCardClick = useCallback(() => {
		if (isMobile) {
			dispatch(setTrackersOpen(false));
		}
		if (isSelected) {
			dispatch(setSelectedTrackerId(null));
			return;
		}
		const coordinates: [number, number] = [
			_.get(tracker, 'packet.LONGITUDE', 0),
			_.get(tracker, 'packet.LATITUDE', 0)
		];

		if (isInvalidCoords(coordinates)) {
			dispatch(openDialog({ children: <GpsErrorPopup /> }));
			return;
		}

		dispatch(setSelectedTrackerId(tracker._id));
		map.flyTo({ center: coordinates, zoom: 16 });
	}, [tracker, map, dispatch, isSelected, isMobile]);

	const layout = useMemo(
		() =>
			({
				primary: (
					<DefaultLayout
						handleCardClick={handleCardClick}
						isDarkMode={isDarkMode}
						parsedDates={parsedDates}
						tooltipText={tooltipText}
						t={t}
						tracker={tracker}
						trackerClass={trackerClass}
						selected={isSelected}
						displayName={displayName}
					/>
				),
				secondary: (
					<SecondaryLayout
						handleCardClick={handleCardClick}
						isDarkMode={isDarkMode}
						parsedDates={parsedDates}
						tooltipText={tooltipText}
						t={t}
						tracker={tracker}
						trackerClass={trackerClass}
						selected={isSelected}
						displayName={displayName}
					/>
				),
				tertiary: (
					<TertiaryLayout
						handleCardClick={handleCardClick}
						isDarkMode={isDarkMode}
						parsedDates={parsedDates}
						tooltipText={tooltipText}
						t={t}
						tracker={tracker}
						trackerClass={trackerClass}
						selected={isSelected}
						displayName={displayName}
					/>
				)
			}[selectedCardVariant]),
		[
			handleCardClick,
			isDarkMode,
			parsedDates,
			t,
			tracker,
			trackerClass,
			tooltipText,
			selectedCardVariant,
			isSelected,
			displayName
		]
	);

	return <div className="pb-8 m-0 flex justify-center pointer">{layout}</div>;
}

export const itemContent = (index: number, item: TTracker) => <DynamicCards tracker={item} />;
