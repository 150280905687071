import _ from 'lodash';
import { LayersRounded } from '@mui/icons-material';
import { Box, Popover, Stack, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectUser } from 'app/store/user/userSlice';
import { useUpdateUserPreferencesMutation } from 'app/store/api/userSlice';
import OSMImg from './osm.png';
import HybridImg from './maptile-satellite.png';
import { TBasemaps } from '../../types';

type TLayerMenu = {
	title: TBasemaps;
	img: string;
};

const LAYERS: TLayerMenu[] = [
	{
		title: 'osm',
		img: OSMImg
	},
	{
		title: 'satellite',
		img: HybridImg
	}
];

export default function LayersButton() {
	const { t } = useTranslation('mapPage');
	const user = useSelector(selectUser);
	const anchorRef = useRef();
	const [popOpen, setPopOpen] = useState(false);
	const [updateUser] = useUpdateUserPreferencesMutation();

	const currentBaseMap = _.get(user, 'preferences.baseMap', 'osm');

	const handleClose = () => setPopOpen(false);
	const handleLayerClick = (data: TLayerMenu) => {
		updateUser({
			id: user.data.userId,
			preferences: {
				...user.preferences,
				baseMap: data.title
			}
		});
		handleClose();
	};

	return (
		<>
			<Tooltip title={t('CHANGE_MAP')}>
				<Box
					sx={{
						backgroundColor: '#fff',
						width: '29px',
						height: '29px',
						boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
						position: 'absolute',
						top: 144,
						left: 10,
						borderRadius: '4px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
						'&:hover': {
							filter: 'brightness(95%)'
						}
					}}
					component="div"
					ref={anchorRef}
				>
					<LayersRounded
						onClick={() => setPopOpen(true)}
						style={{
							fill: '#000'
						}}
					/>
				</Box>
			</Tooltip>
			<Popover
				id={'layers_button'}
				open={popOpen}
				anchorEl={anchorRef.current}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
				sx={{
					'& .MuiPopover-paper': {
						backgroundColor: '#fff'
					}
				}}
			>
				<Stack
					spacing={1}
					direction="row"
					sx={{
						height: '140px',
						padding: 1
					}}
				>
					{LAYERS.map((data) => (
						<Box
							sx={(theme) => ({
								width: '120px',
								height: '120px',
								overflow: 'hidden',
								cursor: 'pointer',
								transition: 'all 0.1s ease-out',
								border:
									currentBaseMap === data.title
										? `4px solid ${theme.palette.secondary.main}`
										: 'none',
								img: {
									width: '100%',
									height: '100%',
									transition: 'all 0.3s ease-out'
								},
								'&:hover': {
									img: {
										transform: 'scale(1.2)'
									}
								}
							})}
							key={data.title}
							component="div"
							onClick={() => handleLayerClick(data)}
						>
							<img src={data.img} alt={`${data.title} thumb`} />
						</Box>
					))}
				</Stack>
			</Popover>
		</>
	);
}
