import { MenuOutlined } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsFiltersOpen, selectIsTrackersOpen, setTrackersOpen } from 'app/store/map/mapSlice';
import { DRAWER_WIDTH } from './MapConfig';
import FiltersPanel from './components/FiltersPanel';
import MapComponent from './components/MapComponent';
import TrackerList from './components/TrackersList';

export default function MapContent() {
	const dispatch = useDispatch();
	const isTrackersOpen = useSelector(selectIsTrackersOpen);
	const isFilterOpen = useSelector(selectIsFiltersOpen);

	const openTrackersList = () => {
		dispatch(setTrackersOpen(true));
	};
	return (
		<div className="w-full h-full overflow-auto">
			<MapComponent />
			<div className="absolute top-[8px] right-[10px] md:top-[25px] md:right-[25px] z-[400] bg-white rounded-full shadow-lg">
				<IconButton
					aria-label="Abrir Lista de Rastreadores"
					size="medium"
					color="primary"
					onClick={openTrackersList}
				>
					<MenuOutlined />
				</IconButton>
			</div>
			<Drawer
				anchor="right"
				variant="persistent"
				hideBackdrop
				open={isTrackersOpen}
				sx={{
					width: ['100%', DRAWER_WIDTH],
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: {
						width: ['100%', DRAWER_WIDTH],
						boxSizing: 'border-box',
						paddingTop: ['66px', '98px'],
						backgroundColor: (theme) => theme.palette.background.default
					}
				}}
			>
				<TrackerList />
			</Drawer>
			<Drawer
				anchor="right"
				variant="persistent"
				hideBackdrop
				open={isFilterOpen}
				sx={{
					width: ['100%', DRAWER_WIDTH],
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: {
						width: ['100%', DRAWER_WIDTH],
						boxSizing: 'border-box',
						paddingTop: ['48px', '64px'],
						right: [0, DRAWER_WIDTH]
					}
				}}
			>
				<FiltersPanel />
			</Drawer>
		</div>
	);
}
