const locale = {
	APPLICATIONS: 'Aplicações',
	EXAMPLE: 'Exemplo',
	PROFILES: 'Perfis',
	USERS: 'Usuários',
	RECORDS: 'Cadastros',
	RECORDS_MANAGEMENT: 'Gestão de Cadastros',
	FLEETS: 'Frotas',
	FLEETS_MANAGEMENT: 'Gestão de Frotas',
	SIMCARDS: 'Simcards',
	PERMISSIONS: 'Permissões',
	VEHICLES: 'Veículos',
	COMPANIES: 'Empresas',
	TRACKERS: 'Rastreadores',
	MAP: 'Mapa',
	IBUTTONS: 'Identificadores',
	DRIVERS: 'Motoristas',
	URLS: 'URLs',
	REPORTS: 'Relatórios',
	REPORTS_MANAGEMENT: 'Gestão de Relatórios',
	HISTORY: 'Histórico',
	CONTACTS: 'Contatos',
	COMMANDS: 'Comandos',
	COMMANDS_MANAGEMENT: 'Gestão de Comandos',
	FENCE: 'Cerca',
	NOTIFICATIONS: 'Notificações',
	NOTIFICATIONS_MANAGEMENT: 'Gestão de Notificações',
	SESSIONS: 'Sessões',
	RULER: 'Regras',
	GEO_FENCES: 'Cercas virtuais',
	TRIPS: 'Jornadas',
	TRIPS_MANAGEMENT: 'Gestão de Jornadas',
	DRIVERS_MANAGEMENT: 'Gestão de Motoristas',
	COMMANDS_REGISTER: 'Registro de Comandos',
	MONITORING: 'Monitoramento',
	RULER_HISTORY: 'Histórico de Notificações',
	RULES_MANAGEMENT: 'Gestão de Regras',
	SCHEDULE_COMMANDS: 'Agendar Comandos',
	HISTORY_COMMANDS: 'Histórico de Comandos',
	NEW_FEATURES: 'Notas de versão',
	NEW_FEATURES_SUBTITLE: 'Novidades e atualizações',
	SHIPS: 'Embarcações',
	MARINE_MANAGEMENT: 'Gestão de Instalações da marine',
	MONITORING_SETTINGS: 'Configurações',
	MONITORING_EVENTS: 'Eventos',
	MONITORING_DONE: 'Finalizados',
	MONITORING_MANAGEMENT: 'Gestão de Monitoramento',
	ANALYTICS: 'Analítico',
	MONITORING_OPERATOR: 'Operadores',
	EVENTS: 'Eventos',
	TRACKER_METRICS: 'Pacotes Enviados',
	MAINTENANCE_MANAGEMENT: 'Gestão de Manutenção',
	MAINTENANCE: 'Manutenção',
	MAINTENANCE_ITEM: 'Item de manutenção',
	MARINE_ACADEMY: 'Treinamentos',
	COURSES: 'Cursos',
	LESSONS: 'Aulas',
	BLOCK_COMMAND_HISTORY: 'Bloqueio/Desbloqueio',
	ACCESS_PERIOD: 'Horarios de acesso',
	MAINTENANCE_REPORT: 'Relatório de manutenção',
	GEOFENCE_MANAGEMENT: 'Gestão de Cercas Virtuais',
	GEOFENCE: 'Cerca Virtual',
	GEOFENCE_MANAGE: 'Cercas Virtuais',
	GEOFENCE_REPORT: 'Relatório de Cercas Virtuais'
};

export default locale;
