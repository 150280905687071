const locale = {
	APPLICATIONS: 'Applications',
	EXAMPLE: 'Example',
	PROFILES: 'Profiles',
	USERS: 'Users',
	RECORDS: 'Records',
	RECORDS_MANAGEMENT: 'Records management',
	FLEETS: 'Fleets',
	FLEETS_MANAGEMENT: 'Fleets management',
	SIMCARDS: 'Simcards',
	PERMISSIONS: 'Permissions',
	VEHICLES: 'Vehicles',
	COMPANIES: 'Companies',
	TRACKERS: 'Trackers',
	MAP: 'Map',
	IBUTTONS: 'iButtons',
	DRIVERS: 'Drivers',
	URLS: 'URLs',
	REPORTS: 'Reports',
	REPORTS_MANAGEMENT: 'Reports management',
	HISTORY: 'History',
	CONTACTS: 'Contacts',
	COMMANDS: 'Commands',
	COMMANDS_MANAGEMENT: 'Commands management',
	FENCE: 'Fence',
	NOTIFICATIONS: 'Notifications',
	NOTIFICATIONS_MANAGEMENT: 'Notifications management',
	SESSIONS: 'Sessions',
	NOTIFICATION_RULER: 'Notifications Ruler',
	GEO_FENCES: 'Geo Fences',
	TRIPS: 'Trips',
	TRIPS_MANAGEMENT: 'Trips management',
	DRIVERS_MANAGEMENT: 'Drivers management',
	COMMANDS_REGISTER: 'Commands Register',
	MONITORING: 'Monitoring',
	SCHEDULE_COMMANDS: 'Schedule Commands',
	HISTORY_COMMANDS: 'History Commands',
	RULER_HISTORY: 'Ruler History',
	RULES_MANAGEMENT: 'Rules management',
	NEW_FEATURES: 'ChangeLog',
	NEW_FEATURES_SUBTITLE: 'News and updates',
	MONITORING_EVENTS: 'Events',
	SHIPS: 'Ships',
	MARINE_MANAGEMENT: 'Marine installations management',
	MONITORING_SETTINGS: 'Settings',
	MONITORING_DONE: 'Done',
	MONITORING_MANAGEMENT: 'Monitoring management',
	ANALYTICS: 'Analytics',
	MONITORING_OPERATOR: 'Operators',
	EVENTS: 'Events',
	TRACKER_METRICS: 'Tracker Metrics',
	MAINTENANCE_MANAGEMENT: 'Maintenance Management',
	MAINTENANCE: 'Maintenance',
	MAINTENANCE_ITEM: 'Maintenance Item',
	BLOCK_COMMAND_HISTORY: 'Block/Unblock',
	ACCESS_PERIOD: 'Access Period',
	MAINTENANCE_REPORT: 'Maintenance Report',
	COURSES: 'Courses',
	LESSONS: 'Lessons',
	GEOFENCE_MANAGEMENT: 'Geofence Management',
	GEOFENCE: 'Geofence',
	GEOFENCE_MANAGE: 'Geofences',
	GEOFENCE_REPORT: 'Geofence Report'
};

export default locale;
