import { FuelTypes } from '../../../../types/fuel-supply.types';

const pt = {
	[FuelTypes.BIODIESEL]: 'Biodiesel',
	[FuelTypes.CNG]: 'GNV',
	[FuelTypes.DIESEL]: 'Diesel',
	[FuelTypes.ETHANOL]: 'Etanol',
	[FuelTypes.GASOLINE]: 'Gasolina',

	SUPPLY_DATE: 'Data de Abastecimento',
	VEHICLE: 'Veículo',
	FUEL_TYPE: 'Tipo de Combustível',
	ODOMETER: 'Odômetro',
	PRICE: 'Preço',
	FUEL_VOLUME: 'Volume de Combustível',
	FUEL_PRICE: 'Preço do Combustível',
	ESTABLISHMENT: 'Estabelecimento',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	FUEL_SUPPLY: 'Abastecimento',
	SHOW_FILTERS: 'Mostrar Filtros',
	SEARCH: 'Pesquisar',
	ADD_MORE: 'Adicionar',
	CONFIG_TABLE: 'Configurar Tabela',
	ACTIONS: 'Ações',
	EDIT: 'Editar',
	DELETE: 'Excluir',
	CONFIRM: 'Confirmar',
	CANCEL: 'Cancelar',

	COMPANY: 'Empresa',
	ERROR_DELETING_FUEL_SUPPLY: 'Erro ao excluir o abastecimento',
	DELETE_FUEL_SUPPLY: 'Excluir Abastecimento',
	DELETE_FUEL_SUPPLY_CONFIRM: 'Você tem certeza que deseja excluir este abastecimento?',
	DELETE_FUEL_SUPPLY_CANCEL: 'Essa ação não poderá ser desfeita!',
	TOTAL_PRICE: 'Preço Total',
	DAYS: '{{n}} Dias',
	TOTAL_VOLUME: 'Volume Total',
	SUPPLIES: 'Abastecimentos',
	AVERAGE_SUPPLY_PRICE: 'Preço Médio de Abastecimento',
	CLEAR_FILTERS: 'Limpar filtros',
	DELETE_MODAL_TITLE: 'Excluir Abastecimento',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja excluir este abastecimento?',
	DELETE_MODAL_WARNING: 'Essa ação não poderá ser desfeita!',
	SUPPLY_DETAILS: 'Detalhes do Abastecimento',
	ATTACHMENTS: 'Anexos',
	NO_ATTACHMENTS: 'Nenhum anexo encontrado',
	VIEW: 'Visualizar'
};

export default pt;
