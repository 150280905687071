import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const HistoryPage = lazyRetry(() => import('./History'));

i18next.addResourceBundle('en', 'historyPage', en);
i18next.addResourceBundle('sp', 'historyPage', sp);
i18next.addResourceBundle('pt', 'historyPage', pt);

export const HISTORY_PERMISSIONS = {
	edit: 'reports.analytic.edit',
	view: 'reports.analytic.view',
	list: 'reports.analytic.list',
	new: 'reports.analytic.new',
	delete: 'reports.analytic.delete',
	minimal: ['fleet.fleet.list', 'fleet.tracker.list', 'fleet.vehicle.list', 'realtime.history.view']
};

const HistoryConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['reports.analytic.list'],
			path: '/reports/analytic',
			element: (
				<LazyLoaderSuspense>
					<HistoryPage />
				</LazyLoaderSuspense>
			),
			children: [{ path: '/reports/analytic/:id' }]
		}
	]
};

export default HistoryConfig;
