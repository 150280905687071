import ConfirmationModal from 'app/shared-components/ConfirmationModal';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch } from 'app/store/index';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import useBlockTracker from '../../hooks/useBlockTracker';
import BlockButton from './BlockButton';

interface IBlockComponentProps {
	trackerDID: number;
	isBlocked: boolean;
	isRealtimeLoading: boolean;
	t: TFunction;
	onSuccess: () => void;
	isWaiting: boolean;
}

export default function BlockComponent({
	trackerDID,
	isBlocked,
	t,
	onSuccess,
	isRealtimeLoading,
	isWaiting
}: IBlockComponentProps) {
	const { block, unblock, unblockLoading, blockLoading, hasBlockCommand, lastBlockCommand, lastBlockTime } =
		useBlockTracker(trackerDID);
	const dispatch = useAppDispatch();
	const loading = blockLoading || unblockLoading || isRealtimeLoading;

	const handleBlockOrUnblock = () => {
		const prefix = isBlocked ? 'UNBLOCK' : 'BLOCK';
		const primary = hasBlockCommand ? 'BLOCK_COMMAND_WARNING' : `${prefix}_TRACKER_CONFIRMATION_TEXT`;

		const commandName = _.get(lastBlockCommand, 'command.commandName', '');

		dispatch(
			openDialog({
				children: (
					<ConfirmationModal
						title={t(`${prefix}_TRACKER_CONFIRMATION`)}
						primary={t(primary, {
							blockTime: dayjs(lastBlockTime).format('DD/MM/YYYY - HH:mm:ss'),
							commandName
						})}
						secondary={!hasBlockCommand && t(`${prefix}_TRACKER_CONFIRMATION_TEXT_WARNING`)}
						onConfirm={isBlocked ? onUnblock : onBlock}
						onCancel={closeModal}
					/>
				)
			})
		);
	};

	const onUnblock = async () => {
		closeModal();
		await unblock();
		onSuccess();
	};

	const onBlock = async () => {
		closeModal();
		await block();
		onSuccess();
	};

	const closeModal = () => dispatch(closeDialog());

	return (
		<BlockButton
			onClick={handleBlockOrUnblock}
			isBlocked={isBlocked}
			isLoading={loading}
			isWaiting={isWaiting}
			t={t}
		/>
	);
}
