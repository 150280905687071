import { Feature, FeatureCollection, point, Point, Polygon, polygon } from '@turf/helpers';
import dayjs from 'dayjs';
import _ from 'lodash';

import { TrackerStatus } from 'app/main/trackers/manage/types';
import { TFunction } from 'react-i18next';
import { TFencePolygonProps, TIconPointProps, TTracker, TTrackerClass } from './types';

export const TRACKER_STATE_COLORS: { [_key in TTrackerClass]: string } = {
	'gps-error': '#25276B',
	'moving-cars': '#348A5D',
	'not-connected-cars': '#AD2C33',
	'stopped-cars': '#2A959B',
	'towed-cars': '#FFA63D',
	'without-vehicle': '#ff9b54',
	blocked: '#AD2C33'
};

export const TRACKER_STATE_POPUPS: { [_key in TTrackerClass]: string } = {
	'gps-error': 'popup_blue',
	'moving-cars': 'popup_green',
	'not-connected-cars': 'popup_red',
	'stopped-cars': 'popup_aqua',
	'towed-cars': 'popup_yellow',
	'without-vehicle': 'popup_orange',
	blocked: 'popup_red'
};

export const TRACKER_STATE_COLORS_NAME: { [_key in TTrackerClass]: string } = {
	'gps-error': 'blue',
	'moving-cars': 'green',
	'not-connected-cars': 'red',
	'stopped-cars': 'aqua',
	'towed-cars': 'yellow',
	'without-vehicle': 'orange',
	blocked: 'red'
};

export const TRACKERS_SRC = {
	cluster: 'trackers-clusterized',
	noCluster: 'trackers-unclusterized'
};

export function getTrackerClassText(trackerClass: TTrackerClass, t: TFunction) {
	const labels = {
		'gps-error': t('WRONG_POSITION'),
		'moving-cars': t('MOVING'),
		'not-connected-cars': t('NO_COMM'),
		'stopped-cars': t('IDLE'),
		'towed-cars': t('TOWED'),
		'without-vehicle': t('WITHOUT_VEHICLE'),
		blocked: t('BLOCKED')
	};

	return labels[trackerClass];
}

export function getTrackerClass(props: TTracker): TTrackerClass {
	if (!props.packet) return 'not-connected-cars';
	const { IGNITION_ON, LATITUDE, LONGITUDE, SPEED, GPS_TIME, SERVER_TIME, IS_LOGICALLY_BLOCKED } = _.pick(
		props.packet,
		[
			'IGNITION_ON',
			'LATITUDE',
			'LONGITUDE',
			'SPEED',
			'GPS_TIME',
			'SERVER_TIME',
			'BLOCK_STATUS',
			'IS_BLOCKED',
			'IS_LOGICALLY_BLOCKED'
		]
	);

	if (IS_LOGICALLY_BLOCKED && props?.canBeBlocked) {
		return 'blocked';
	}

	if (!props.vehicle) {
		return 'without-vehicle';
	}

	if (LATITUDE === 0 && LONGITUDE === 0) {
		return 'gps-error';
	}

	if (!GPS_TIME) {
		return 'gps-error';
	}
	if (dayjs().diff(dayjs(SERVER_TIME), 'hours') > 24) {
		return 'not-connected-cars';
	}

	if (IGNITION_ON && SPEED > 5) {
		return 'moving-cars';
	}

	if (IGNITION_ON && SPEED <= 5) {
		return 'stopped-cars';
	}

	if (!IGNITION_ON && SPEED > 5) {
		return 'towed-cars';
	}

	if (!IGNITION_ON && SPEED <= 5) {
		return 'stopped-cars';
	}

	return 'not-connected-cars';
}
const trackersReducer = (trackers: TTracker[], stateFilter: TTrackerClass[]): Feature<Point, TIconPointProps>[] =>
	trackers.reduce((prev, curr) => {
		const status = _.get(curr, 'status', false);

		if (status && status !== TrackerStatus.ACTIVE) {
			return prev;
		}
		const coordinates: number[] = [_.get(curr, 'packet.LONGITUDE', 0), _.get(curr, 'packet.LATITUDE', 0)];
		if (coordinates.some((coord) => coord === 0 || coord > 90 || coord < -90)) return prev;
		const trackerState = getTrackerClass(curr);
		if (stateFilter.length && !stateFilter.includes(trackerState)) return prev;
		const bearing: number = _.get(curr, 'packet.BEARING', 0);
		const icon = `n1_${TRACKER_STATE_COLORS_NAME[trackerState]}`;

		const props = {
			..._.pick(curr, ['_id', 'did']),
			name: _.get(curr, 'vehicle.name', ''),
			icon,
			bearing,
			trackerState,
			lng: coordinates[0],
			lat: coordinates[1],
			popupImage: TRACKER_STATE_POPUPS[trackerState],
			popupText: _.get(curr, 'vehicle.name', curr.did)
		};
		return [...prev, point(coordinates, props)];
	}, []);

export const getTrackersGeojson = (
	trackers: TTracker[],
	stateFilter: TTrackerClass[]
): FeatureCollection<Point, TIconPointProps> => ({
	type: 'FeatureCollection',
	features: trackersReducer(trackers, stateFilter)
});

const geoFencesReducer = (fences, fenceFilter: string[]): FeatureCollection<Polygon, TFencePolygonProps> =>
	fences.reduce((prev, curr) => {
		const id = curr._id;

		if (fenceFilter.length && !fenceFilter.includes(id)) return prev;

		const features = _.get(curr, 'geojson.features', []);

		const props = {
			id,
			name: _.get(curr, 'name', ''),
			description: _.get(curr, 'description', '')
		};

		try {
			const coordinates = _.get(features, '[0].geometry.coordinates', []);
			const newPolygon = polygon(coordinates, props);
			return [...prev, newPolygon];
		} catch (error) {
			return prev;
		}
	}, []);

export const getGeoFencesGeojson = (fences, stateFilter: string[]): FeatureCollection<Polygon, TFencePolygonProps> => ({
	type: 'FeatureCollection',
	features: geoFencesReducer(fences, stateFilter)
});

export const popupImgParams: { stretchX: [number, number][]; content: [number, number, number, number] } = {
	stretchX: [[8, 115]],
	content: [8, 4, 115, 22]
};

export function getSelectedOptions(newValue: TTrackerClass, current: TTrackerClass[]) {
	const currentIndex = current.indexOf(newValue);
	const newChecked = [...current];

	if (currentIndex === -1) {
		newChecked.push(newValue);
	} else {
		newChecked.splice(currentIndex, 1);
	}
	return newChecked;
}

export const getTrackerDisplayName = (tracker: TTracker) => {
	const vehicleName = _.get(tracker, 'vehicle.name', '');
	const trackerName = _.get(tracker, 'name', '');
	const ddi = _.get(tracker, 'did', '');

	return vehicleName || trackerName || ddi.toString();
};

export const isInvalidCoords = (coords: number[]) => coords.some((coord) => coord === 0 || coord > 90 || coord < -90);
