const locale = {
	// Columns
	NAME: 'Nome',
	ACTIVE: 'Ativo',
	INACTIVE: 'Inativo',
	COMPANY: 'Empresa',
	FLEETS: 'Frotas',
	VEHICLES: 'Veículos',
	BLACKLIST: 'Lista Negra',
	TO_HIGH_PRIORITY: 'Prioridade Alta',
	TO_LOW_PRIORITY: 'Prioridade Baixa',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	UPDATED_AT: 'Atualizado em',
	CANCEL: 'Cancelar',
	BACK: 'Voltar',
	FINISH: 'Finalizar',
	CONTINUE: 'Continuar',
	CONFIRM: 'Confirmar',
	UPDATE_ERROR: 'Erro ao atualizar configuração de monitoramento',
	CREATE_ERROR: 'Erro ao criar configuração de monitoramento',

	// Table
	NO_SETTINGS_FOUND: 'Nenhuma configuração encontrada',
	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',
	N_FLEETS: '{{n}} frotas',
	N_VEHICLES: '{{n}} veículos',
	N_ITEMS: '{{n}} itens',

	// Header
	TITLE: 'Configurações',
	ADD_NEW_SETTING: 'Adicionar nova configuração',

	// Sidebar
	SELECTED_ITEMS: '{{n}} Itens selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar configurações de monitoramento',
	DELETE_MODAL_TITLE: 'Deletar configurações de monitoramento',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar estas configurações?',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_ERROR: 'Erro ao deletar configurações de monitoramento',
	DOWNLOAD_INFO: 'Baixar configurações de monitoramento',
	DOWNLOAD_ERROR: 'Erro ao baixar configurações de monitoramento',
	DELETE_SUCCESS: 'Configurações deletadas com sucesso',

	// DialogForm
	EDIT_MONITORING_SETTINGS: 'Editar configuração',
	MONITORING_SETTINGS: 'Configuração de Monitoramento',
	NAME_AND_COMPANY: 'Nome e empresa',
	TARGETS: 'Itens monitorados',
	REVIEW: 'Revise as configurações',
	// DialogForm - name and company
	SELECT_COMPANY: 'Selecione uma empresa',
	SELECT_NAME_AND_COMPANY: 'Adicione um nome e empresa para a configuração',
	// DialogForm - targets
	SELECT_TARGETS_HEADER: 'Selecione os itens que serão monitorados',
	TARGET_FLEETS: 'Frotas',
	FLEETS_LIMIT_TEXT: '{{n}} frotas selecionadas',
	TARGET_VEHICLES: 'Veículos',
	VEHICLES_LIMIT_TEXT: '{{n}} veículos selecionados',
	HIGH: 'Alta',
	MEDIUM: 'Média',
	LOW: 'Baixa',
	SHOW: 'Mostrar',
	PRIORITY: 'Prioridade',
	// DialogForm - review
	REVIEW_PAGE: 'Revise as configurações',

	// DialogView
	BLACKLISTED_ITEMS: 'Alertas excluídos',
	PRIORITIES: 'Alertas',
	SENT_FROM: 'Enviado de',
	FLEET: 'Frota',
	VEHICLE: 'Veículo',
	DELETE: 'Deletar configuração',
	EDIT: 'Editar configuração',
	NO_BLACKLISTED_ITEMS: 'Nenhum alerta excluído',
	SELECT_ALL: 'Selecionar Todos',

	// Validation
	NAME_REQUIRED: 'O campo nome é obrigatório',
	COMPANY_REQUIRED: 'O campo empresa é obrigatório',

	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',
};

export default locale;
