const locale = {
	TITLE: 'Relatório de Jornadas',
	DOWNLOAD_ALL: 'Baixar todos',
	DOWNLOAD_SELECTED: 'Baixar selecionados',

	REPORT_HISTORY: 'Relatório de histórico',
	COMPANY: 'Empresa',

	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar tabela',
	TRACKER_REALTIME: 'Tempo real deste rastreador',
	SEE_REALTIME_MAP: 'Ir para mapa em tempo real',
	SUMMARY_HISTORY: 'Resumo deste Histórico',
	DURATION: 'Duração',
	MILEAGE: 'Km Rodado',
	FILTER_DESCRIPTION: 'Os campos abaixo, mostrarão os dados apenas das empresas selecionadas.',

	OPEN_ADVANCED: 'Histórico avançado',

	SELECT_TRIP: 'Selecionar viagem',
	NO_TRIPS: 'Nenhuma viagem encontrada',
	TRIPS: 'Jornadas',
	TRIP_DETAILS: 'Detalhes da jornada',
	SELECT_TRACKER: 'Selecione um rastreador',
	TRACKER_REQUIRED: 'É necessário selecionar um rastreador',
	COMPANY_REQUIRED: 'Empresa é obrigatório',
	DRIVERS: 'Motoristas',
	DRIVERS_SELECTED: '{{n}} motoristas',

	NO_TRACKERS_IN_COMPANIES: 'Nenhum rastreador disponível para as empresas selecionadas',
	NO_VEHICLES_IN_COMPANIES: 'Nenhum veículo disponível para as empresas selecionadas',
	NO_FLEETS_IN_COMPANIES: 'Nenhuma frota disponível para as empresas selecionadas',
	NO_DRIVERS_IN_COMPANIES: 'Nenhum motorista disponível para as empresas selecionadas',
	ERROR_GETTING_TRIPS: 'Erro ao buscar jornadas',

	START_TIME: 'Data e hora de início',
	END_TIME: 'Data e hora de término',
	AVG_SPEED: 'Velocidade média',
	NO_FILTERS: 'Nenhum filtro aplicado',
	NO_FILTERS_SUBTITLE: 'Selecione os filtros que deseja para ver os resultados',

	COULDNT_FIND_TRIP: 'Não foi possível encontrar os dados da jornada',
	COULDNT_FIND_TRIP_END: 'Por favor, contate o administrador do sistema.    Id da jornada: {{id}}',

	END_SHALL_BE_GREATER: 'Data final deve ser maior que a data inicial',
	AT_LEAST_ONE_FILTER: 'Pelo menos um filtro deve ser selecionado',

	CLOSE: 'Fechar',

	EVENT_TYPE: 'Evento',
	GPS_TIME: 'Horário',
	ALTITUDE: 'Altitude',
	BEARING: 'Ângulo',
	SATELLITES: 'Satélites',
	LATITUDE: 'Latitude',
	LONGITUDE: 'Longitude',
	ADDRESS_CITY: 'Cidade',
	ADDRESS_COUNTRY: 'País',
	ADDRESS_POSTCODE: 'CEP',
	ADDRESS_ROAD: 'Rua',
	ADDRESS_STATE: 'Estado',
	ADDRESS_SUBURB: 'Bairro',
	HOURMETER: 'Hourimetro',
	SPEED: 'Velocidade',
	IGNITION_ON: 'Ignição ligada',
	IGNITION_OFF: 'Ignição desligada',
	GPS_FIXED: 'GPS fixado',
	GPS_NOT_FIXED: 'GPS não fixado',
	SIGNAL_LEVEL: 'Intensidade de sinal',
	ODOMETER: 'Odômetro',
	BATTERY: 'Bateria',
	OPERATOR: 'Operadora',
	YES: 'Sim',
	NO: 'Não',
	BACK: 'Voltar',
	UNAVAILABLE: 'Indisponível',
	START_ADDRESS: 'Endereço de início',
	END_ADDRESS: 'Endereço de término',
	TOTAL_DURATION: 'Duração total',
	TOTAL_DISTANCE: 'Distância percorrida',
	TOTAL: 'Quantidade de jornadas',
	SUMMARY_DESCRIPTION: 'Para ver detalhes de varías jornadas, selecione-as na tabela e clique em "Gerar resumo"',
	SUMMARY: 'Resumo'
};

export default locale;
