/* eslint-disable react/jsx-pascal-case */
import { Box, Paper, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';

import { DeviceHub, PersonOutline, SatelliteAltOutlined } from '@mui/icons-material';
import { IgnitionIcon } from 'app/shared-components/tracker-icons';
import { BatteryIcon } from 'app/shared-components/tracker-icons/BatteryIcon';
import { Fragment } from 'react';
import formatBattery from 'src/utils/formatBattery';
import { ITrackerCardProps } from '.';
import CardActions from './CardActions';
import {
	DateIcon,
	// SignalIcon,
	SpeedIcon,
	TimeIcon,
	TrackerIcon
} from './ControlledIcons';
import { IconValue, Label, LabeledIcon } from './LabeledIcons';

export default function DefaultLayout({
	tracker,
	isDarkMode,
	tooltipText,
	trackerClass,
	handleCardClick,
	parsedDates,
	t,
	selected,
	displayName
}: ITrackerCardProps) {
	const driver = _.get(tracker, 'iButton.driver.name', null);
	const serialCode = _.get(tracker, 'packet.SERIAL_CODE', null);
	const displayDriver = driver || serialCode;
	const satellites = _.get(tracker, 'packet.SATELLITES', 0);
	const ignOn = _.get(tracker, 'packet.IGNITION_ON', false);
	const hasBatteryStatus = _.has(tracker, 'packet.BATTERY_STATUS');
	const battery = t(
		formatBattery(_.get(tracker, 'packet.VEHICLE_VOLTAGE', 0), _.get(tracker, 'packet.BATTERY_STATUS'))
	);

	return (
		<Paper
			sx={{
				width: ['100%', '390px'],
				height: '171px',
				margin: 0,
				borderRadius: '12px',
				padding: '15px 20px',
				cursor: 'pointer',
				backgroundColor: (theme) => selected && `${theme.palette.secondary.main}1a`,
				border: (theme) => selected && `1px solid ${theme.palette.secondary.main}`
			}}
			variant="outlined"
			onClick={handleCardClick}
		>
			<Stack spacing={1} height="100%" justifyContent="space-between">
				<Stack direction="row" justifyContent="space-between" alignItems="start">
					<Stack direction="row" alignItems="center" spacing={1}>
						<DeviceHub color="disabled" fontSize="small" />
						<Typography fontSize={12} color="text.disabled">
							{_.get(tracker, 'did', '0')}
						</Typography>

						{displayDriver && (
							<Fragment>
								<PersonOutline color="disabled" fontSize="small" />
								<Typography fontSize={12} color="text.disabled">
									{displayDriver}
								</Typography>
							</Fragment>
						)}
					</Stack>
					<CardActions tracker={tracker} />
				</Stack>

				<Box
					sx={{
						display: 'grid',
						gridTemplateAreas: `"icon name name"
								 "icon date time"	
								`,
						gridTemplateColumns: '45px auto auto',

						width: '100%',
						alignItems: 'center'
					}}
				>
					<Box gridArea="icon">
						<Tooltip title={tooltipText}>
							<TrackerIcon
								isDarkMode={isDarkMode}
								trackerClass={trackerClass}
								iconName={_.get(tracker, 'vehicle.type.icon', 'mat_outline:devices_other')}
							/>
						</Tooltip>
					</Box>
					<Box gridArea="name">
						<Typography fontWeight={600} fontSize={18} maxWidth={220} textAlign="start" noWrap>
							{displayName}
						</Typography>
					</Box>
					<Box gridArea="date">
						{parsedDates ? (
							<Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
								<DateIcon isDarkMode={isDarkMode} />
								<Typography
									sx={{
										fontStyle: 'normal',
										fontWeight: 600,
										fontSize: '12px',
										lineHeight: '24px',
										color: isDarkMode ? '#9FA5A1' : '#79747E'
									}}
								>
									{_.get(parsedDates, 'date', '')}
								</Typography>
							</Stack>
						) : undefined}
					</Box>
					<Box gridArea="time">
						{parsedDates ? (
							<Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
								<TimeIcon isDarkMode={isDarkMode} />
								<Typography
									sx={{
										fontStyle: 'normal',
										fontWeight: 600,
										fontSize: '12px',
										color: isDarkMode ? '#9FA5A1' : '#79747E'
									}}
								>
									{_.get(parsedDates, 'time', '')}
								</Typography>
							</Stack>
						) : undefined}
					</Box>
				</Box>
				<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="end">
					<LabeledIcon
						label={<Label color={isDarkMode ? '#9FA5A1' : '#79747E'}>{t('SPEED')}</Label>}
						icon={<SpeedIcon isDarkMode={isDarkMode} />}
						iconValue={
							<div className="flex">
								<IconValue color={isDarkMode ? '#fff' : '#000'}>
									{`${_.get(tracker, 'packet.SPEED', '0')}`}
								</IconValue>
								<Box
									sx={{
										fontStyle: 'normal',
										fontWeight: 600,
										fontSize: '10px',
										lineHeight: '24px',
										paddingTop: '2px',
										color: isDarkMode ? '#fff' : '#000'
									}}
								>
									km/h
								</Box>
							</div>
						}
					/>
					<LabeledIcon
						label={<Label color={isDarkMode ? '#9FA5A1' : '#79747E'}>{t('IGNITION')}</Label>}
						icon={<IgnitionIcon on={ignOn} />}
					/>
					<LabeledIcon
						label={<Label color={isDarkMode ? '#9FA5A1' : '#79747E'}>{t('BATTERY')}</Label>}
						icon={
							hasBatteryStatus ? (
								<div className="flex flex-row items-center">
									<BatteryIcon batteryStatus={_.get(tracker, 'packet.BATTERY_STATUS')} />
									{battery}
								</div>
							) : (
								battery
							)
						}
					/>
					<LabeledIcon
						label={<Label color={isDarkMode ? '#9FA5A1' : '#79747E'}>{t('SATELLITE')}</Label>}
						icon={<SatelliteAltOutlined fontSize="small" />}
						iconValue={<IconValue color={isDarkMode ? '#fff' : '#000'}>{satellites}</IconValue>}
					/>
				</Stack>
			</Stack>
		</Paper>
	);
}
