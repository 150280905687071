const locale = {
	TITLE: 'Mapa',
	FILTERS: 'Filtros',
	COMPANIES: 'Empresas',
	VEHICLES: 'Veículos',
	FLEETS: 'Frotas',
	STATES: 'Estados',
	SEARCH: 'Pesquisar...',
	ADVANCED_FILTERS: 'Filtros Avançados',
	VEHICLE_TYPE: 'Tipo de Veículo',
	IGNITION_ON: 'Ignição Ligada',
	SPEED: 'Velocidade',
	GREEN: 'Verde',
	RED: 'Vermelho',
	YELLOW: 'Amarelo',
	PURPLE: 'Roxo',
	GRAY: 'Cinza',
	BOAT: 'Barco',
	CAR: 'Carro',
	BIKE: 'Bicicleta',
	TRUCK: 'Caminhão',
	MOTORBIKE: 'Moto',
	OTHERS: 'Outros',
	APPLY: 'Aplicar',
	SAVE: 'Salvar',
	NAME: 'Nome',
	EQUALS: 'Igual',
	GREATER_THAN: 'Maior que',
	LESS_THAN: 'Menor que',
	MY_FILTERS: 'Meus filtros',
	SUCCESS_SAVE_FILTER: 'Filtro criado com sucesso',
	ERROR_SAVE_FILTER: 'Error ao criar filtro',
	SUCCESS_DELETE_FILTER: 'Filtro excluído com sucesso',
	ERROR_DELETE_FILTER: 'Error ao deletar filtro',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	SELECTED_FLEETS: '{{n}} Frotas selecionadas',
	SELECTED_STATES: '{{n}} Estados selecionados',
	SELECTED_VEHICLE_TYPES: '{{n}} Tipo de veículo selecionados',
	SELECTED_VEHICLES: '{{n}} Veículos selecionados',
	MAKE_DEFAULT: 'Definir como filtro padrão',
	ON: 'Ligada',
	OFF: 'Desligada',
	ON_OFF: 'Ligada e desligada',
	SUCCESS_DEFAULT_FILTER: 'Filtro setado como padrão',
	SUCCESS_DEFAULT_FILTER_UNSET: 'Filtro padrão removido',
	ERROR_DEFAULT_FILTER: 'Error ao setar filtro como padrão',
	SUCCESS_APPLY_FILTER: 'Filtro aplicado com sucesso',
	HISTORY: 'Histórico',
	REAL_TIME: 'Tempo Real',
	FROM: 'De:',
	TO: 'Até:',
	SELECT_RANGE: 'Selecione um intervalo',
	NO_HISTORY: 'Este veículo não possui historico',
	HISTORY_ERROR: 'Error ao buscar histórico',
	CANCEL: 'Cancelar',
	DISTANCE: 'Distância',
	DURATION: 'Duração',
	SHOW_POSITIONS: 'Mostrar posições:',
	SHOW_TRACKERS: 'Mostrar rastreadores:',
	IGNITION: 'Ignição',
	SATELLITE: 'Satélite',
	OPERATOR: 'Operadora',
	BATTERY: 'Tensão',
	IDLE: 'Parado',
	MOVING: 'Em movimento',
	NO_COMM: 'Sem comunicação',
	WRONG_POSITION: 'Posicionamento errado',
	TOWED: 'Guinchado',
	CONNECTED: 'Conectado',
	TRACKER: 'Rastreador:',
	DEVICE: 'Dispositivo:',
	DATE: 'Data:',
	TIME: 'Hora:',
	SIGNAL: 'Sinal',
	IDLE_N: 'Parado <i>({{n}})</i>',
	MOVING_N: 'Em movimento <i>({{n}})</i>',
	NO_COMM_N: 'Sem comunicação <i>({{n}})</i>',
	WRONG_POSITION_N: 'Posicionamento errado <i>({{n}})</i>',
	TOWED_N: 'Guinchado <i>({{n}})</i>',
	BLOCKED_QUANTITY: 'Bloqueado <i>({{n}})</i>',
	CLOSE: 'Fechar',
	GEOFENCE_LOAD_ERROR: 'Erro ao buscar cercas virtuais',
	FAST_HISTORY: 'Histórico rápido',
	ADVANCED_HISTORY: 'Histórico avançado',
	START_DATE_MUST_BE_BEFORE_END_DATE: 'A data de início deve ser anterior a data final',
	FOLLOW_VEHICLE: 'Seguir veículo',
	CANNOT_GET_TRIP: 'Não foi possível obter os dados da jornada',
	ROUTE_POINT_BY_POINT: 'Trajeto ponto a ponto',
	ROUTERIZED_ROUTE: 'Trajeto roteirizado',
	EVENT_TYPE: 'Evento',
	GPS_TIME: 'Horário',
	ALTITUDE: 'Altitude',
	BEARING: 'Ângulo',
	SATELLITES: 'Satélites',
	LATITUDE: 'Latitude',
	LONGITUDE: 'Longitude',
	ADDRESS_CITY: 'Cidade',
	ADDRESS_COUNTRY: 'País',
	ADDRESS_POSTCODE: 'CEP',
	ADDRESS_ROAD: 'Rua',
	ADDRESS_STATE: 'Estado',
	ADDRESS_SUBURB: 'Bairro',
	HOURMETER: 'Hourimetro',
	MILEAGE: 'Quilometragem',
	YES: 'Sim',
	NO: 'Não',
	CLEAR: 'Limpar',
	VIEW_TRACKER: 'Visualizar rastreador',
	VIEW_VEHICLE: 'Visualizar veículo',
	ADDRESS: 'Endereço',
	STATUS: 'Status',
	UNAVAILABLE: 'Indisponível',
	LOCATION: 'Localização',
	VOLTAGE: 'Voltagem',
	LAST_POSITION: 'Última posição',
	TRACKER_PROBLEM: 'Clique aqui para mostrar seus rastreadores com defeito',
	CHANGE_MAP: 'Mudar Mapa',
	WITHOUT_VEHICLE: 'Sem veículo',
	WITHOUT_VEHICLE_N: 'Sem veículo <i>({{n}})</i>',
	NO_FILTERS: 'Você não possui filtros',
	BLOCK: 'Bloquear',
	UNBLOCK: 'Desbloquear',
	BLOCK_TRACKER: 'Bloquear rastreador',
	BLOCKED: 'Bloqueado',
	BLOCK_TRACKER_SEND_SUCCESS: 'Comando de bloqueio enviado com sucesso, aguarde a confirmação.',
	BLOCK_TRACKER_SEND_ERROR: 'Erro ao enviar comando de bloqueio.',
	UNBLOCK_TRACKER_SEND_ERROR: 'Erro ao enviar comando de desbloqueio.',
	UNBLOCK_TRACKER_SEND_SUCCESS: 'Comando de desbloqueio enviado com sucesso, aguarde a confirmação.',
	BLOCK_TRACKER_CONFIRMATION: 'Confirmar bloqueio',
	BLOCK_TRACKER_CONFIRMATION_TEXT: 'Você está prestes a bloquear temporariamente este veículo.',
	BLOCK_TRACKER_CONFIRMATION_TEXT_WARNING: 'Para prosseguir, clique em "Confirmar" no botão abaixo.',
	UNBLOCK_TRACKER_CONFIRMATION: 'Confirmar desbloqueio',
	UNBLOCK_TRACKER_CONFIRMATION_TEXT: 'Você está prestes a desbloquear temporariamente este veículo.',
	UNBLOCK_TRACKER_CONFIRMATION_TEXT_WARNING: 'Para prosseguir, clique em "Confirmar" no botão abaixo.',
	CONFIRM: 'Confirmar',
	ACTIONS: 'Ações',
	TO_GOOGLE_MAPS: 'Ver no Google Maps',
	COPY_INFO: 'Copiar informações',
	TRACKER_SETTINGS: 'Configurações do rastreador',
	RESTORE_TVD: 'Restaurar TVD',
	ADJUSTED: 'Configurado',
	LAST_COMMUNICATION: 'Última comunicação',
	LAST_GPS: 'Ultima comunicação mapa ao vivo',
	WAITING: 'Aguardando',
	LAST_PACKET: 'Último pacote',
	LAST_SERVER_TIME: 'Último horário do servidor',
	UPDATED_AT: 'Atualizado em',
	BLOCK_STATUS: 'Status do último bloqueio',
	UNBLOCK_STATUS: 'Status do último desbloqueio',
	LAST_BLOCK_COMMAND: 'Status do último {{command}}',

	PENDING: 'Pendente',
	QUEUED: 'Agendado',
	'RE-QUEUED': 'Re-enfileirado',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	CANCELED: 'Cancelado',
	ERROR: 'Erro',
	EXPIRED: 'Expirado',
	SCHEDULING: 'Agendando',

	BLOCK_COMMAND_WARNING:
		'Este veículo possui um comando de {{commandName}} pendente enviado em: {{blockTime}}, você tem certeza que deseja realizar um nova solicitação?',
	WRONG_POSITION_POPUP_TEXT:
		'O rastreador está com posicionamento errado e não estará disponível no mapa. Verifique se o rastreador foi instalado corretamente ou entre em contato com o suporte.',

	DRIVER: 'Motorista',
	GROUP_TRACKERS: 'Agrupar rastreadores',
	SHOW_TRACKERS_NAME: 'Mostrar nome dos rastreadores',
	SHOW_TRACKERS_GROUP_COUNT: 'Mostrar contagem de rastreadores',
	FILTER: 'Filtrar',
	SHOW_GEO_FENCE: 'Mostrar cerca virtual',

	ROUTERIZED_DISTANCE: 'Distância roteirizada',

	START: 'Início',
	END: 'Fim',
	SENT_AT: 'Enviado em',
	RECEIVED_AT: 'Recebido em',

	LAST_GPS_TIME: 'Data da última localização (GPS)',
	VIEW_ANALYTIC_REPORT: 'Ver relatório analítico',

	SHUTDOWN: 'Desligado',
	EXTREMELY_LOW_POWER: 'Baixíssima',
	VERY_LOW_POWER: 'Muito baixa',
	LOW_BATTERY: 'Baixa',
	MEDIUM: 'Média',
	HIGH: 'Alta',
	VERY_HIGH: 'Muito alta',
	UNKNOWN: 'Desconhecido',

	PUBLIC_LINK: 'Link público',
	CREATE_PUBLIC_LINK: 'Criar Link Público',
	'24_HOURS': '24 Horas',
	'48_HOURS': '48 Horas',
	'72_HOURS': '72 Horas',
	CREATE: 'Criar',
	PUBLIC_LINK_ERROR: 'Erro ao criar link público',
	PUBLIC_LINK_INFO:
		'Este link público permitirá o acesso temporário às informações do veículo. Escolha a duração da validade do link abaixo. Após a criação, você será redirecionado para a página do link público.'
};

export default locale;
