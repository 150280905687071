const locale = {
	TITLE: 'Informe de Jornadas',
	DOWNLOAD_ALL: 'Descargar todo',
	DOWNLOAD_SELECTED: 'Descargar seleccionados',

	REPORT_HISTORY: 'Informe de historial',
	COMPANY: 'Empresa',

	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar tabla',
	TRACKER_REALTIME: 'Tiempo real de este rastreador',
	SEE_REALTIME_MAP: 'Ver mapa en tiempo real',
	SUMMARY_HISTORY: 'Resumen de este historial',
	DURATION: 'Duración',
	MILEAGE: 'Kilometraje',
	FILTER_DESCRIPTION: 'Los campos a continuación mostrarán los datos solo de las empresas seleccionadas.',

	OPEN_ADVANCED: 'Historial avanzado',

	SELECT_TRIP: 'Seleccionar viaje',
	NO_TRIPS: 'No se encontraron viajes',
	TRIPS: 'Jornadas',
	TRIP_DETAILS: 'Detalles del viaje',
	SELECT_TRACKER: 'Selecciona un rastreador',
	TRACKER_REQUIRED: 'Debes seleccionar un rastreador',
	COMPANY_REQUIRED: 'Empresa obligatoria',
	DRIVERS: 'Conductores',
	DRIVERS_SELECTED: '{{n}} conductores',

	NO_TRACKERS_IN_COMPANIES: 'No hay rastreadores disponibles para las empresas seleccionadas',
	NO_VEHICLES_IN_COMPANIES: 'No hay vehículos disponibles para las empresas seleccionadas',
	NO_FLEETS_IN_COMPANIES: 'No hay flotas disponibles para las empresas seleccionadas',
	NO_DRIVERS_IN_COMPANIES: 'No hay conductores disponibles para las empresas seleccionadas',
	ERROR_GETTING_TRIPS: 'Error al obtener viajes',

	START_TIME: 'Fecha y hora de inicio',
	END_TIME: 'Fecha y hora de fin',
	AVG_SPEED: 'Velocidad media',
	NO_FILTERS: 'Sin filtros',
	NO_FILTERS_SUBTITLE: 'Selecciona los filtros que deseas aplicar para ver los resultados',

	COULDNT_FIND_TRIP: 'No se pudieron encontrar los datos del viaje',
	COULDNT_FIND_TRIP_END: 'Por favor, contacta al administrador del sistema.    ID del viaje: {{id}}',

	END_SHALL_BE_GREATER: 'La fecha de fin debe ser posterior a la fecha de inicio',
	AT_LEAST_ONE_FILTER: 'Debes seleccionar al menos un filtro',

	CLOSE: 'Cerrar',

	EVENT_TYPE: 'Tipo de evento',
	GPS_TIME: 'Hora GPS',
	ALTITUDE: 'Altitud',
	BEARING: 'Ángulo',
	SATELLITES: 'Satélites',
	LATITUDE: 'Latitud',
	LONGITUDE: 'Longitud',
	ADDRESS_CITY: 'Ciudad',
	ADDRESS_COUNTRY: 'País',
	ADDRESS_POSTCODE: 'Código postal',
	ADDRESS_ROAD: 'Calle',
	ADDRESS_STATE: 'Estado',
	ADDRESS_SUBURB: 'Barrio',
	HOURMETER: 'Horómetro',
	SPEED: 'Velocidad',
	IGNITION_ON: 'Encendido activo',
	IGNITION_OFF: 'Encendido inactivo',
	GPS_FIXED: 'GPS fijado',
	GPS_NOT_FIXED: 'GPS no fijado',
	SIGNAL_LEVEL: 'Nivel de señal',
	ODOMETER: 'Odómetro',
	BATTERY: 'Batería',
	OPERATOR: 'Operador',
	YES: 'Sí',
	NO: 'No',
	BACK: 'Volver',
	UNAVAILABLE: 'No disponible',
	START_ADDRESS: 'Dirección de inicio',
	END_ADDRESS: 'Dirección de fin',
	
	TOTAL_DURATION: 'Duración total',
	TOTAL_DISTANCE: 'Distancia total',
	TOTAL: 'Total de jornadas',
	SUMMARY_DESCRIPTION:
		'Para ver detalles de varios viajes, selecciónalos en la tabla y haz clic en "Generar resumen"',
	SUMMARY: 'Resumen'
};

export default locale;
