import { Box, LinearProgress } from '@mui/material';
import { EntityState, createSelector } from '@reduxjs/toolkit';
import getBbox from '@turf/bbox';
import _ from 'lodash';
import maplibreGl from 'maplibre-gl';
import { useCallback, useEffect, useMemo } from 'react';
import Map, {
	FullscreenControl,
	Layer,
	LngLatLike,
	MapLayerMouseEvent,
	MapRef,
	NavigationControl,
	Source
} from 'react-map-gl/maplibre';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useGetGeoFencesQuery } from 'app/store/api/geoFenceSlice';
import { selectAllTrackers, selectTrackerById, useGetRealtimeQuery } from 'app/store/api/trackingSlice';
import {
	selectGeoFenceFilter,
	selectStateFilter,
	setInitialState as setActionBarInitial
} from 'app/store/map/actionBarSlice';
import { setInitialState as setFiltersInitial } from 'app/store/map/advanceFilterSlice';
import {
	selectMapInstance,
	selectSelectedHistory,
	selectTrackerToWatch,
	setEventPopup,
	setInitialState as setMapInitial,
	setMapInstance,
	setSelectedTrackerId
} from 'app/store/map/mapSlice';
import { setInitialState as setToolbarInitial } from 'app/store/map/mapToolbarSlice';
import { setInitialState as setRouteInitial } from 'app/store/map/routeToolbarSlice';
import { selectUserPreferences } from 'app/store/user/userSlice';
import { usePrevious } from 'src/app/services/hooks';
import { POLLING_INTERVAL } from '../MapConfig';

import { routePositionLayer, useMapLayers, useRouteLayers } from '../hooks';
import { TRACKERS_SRC, TRACKER_STATE_POPUPS, getGeoFencesGeojson, getTrackersGeojson } from '../mapHelpers';
import { clusterLayer, styleBasic } from '../mapLayers';
import type { TPacket, TTracker, TTrackerClass } from '../types';
import EventPopup from './EventPopup';
import MapToolbar from './MapToolbar';

import { TGeoFence } from 'app/main/geoFence/types/types';
import { IApiPagination } from 'app/store/api/types';
import ActionBar from './action-bar';
import StyleControl from './layers-button/LayersButton';
import { TrackerPopup } from './tracker-popup/TrackerPopup';

const emptyArr: unknown[] = [];

export default function MapComponent() {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const mapRef = useSelector(selectMapInstance);
	const { isClustered } = useSelector(selectUserPreferences);
	const currentRoute = useSelector(selectSelectedHistory);
	const watchedTracker = useSelector(selectTrackerToWatch);
	const selectedStateFilter = useSelector(selectStateFilter);
	const selectedGeoFences = useSelector(selectGeoFenceFilter);

	const {
		controlledClusterCountLayer,
		controlledClusterLayer,
		controlledPopupLayer,
		controlledUnclusteredPointLayer,
		controlledFenceFillLayer,
		controlledFenceLineLayer,
		controlledHybridLayer,
		controlledOSMLayer
	} = useMapLayers();

	const {
		controlledRouteByPosition,
		controlledRouteStartStopLayer,
		controlledRouteLine,
		controlledRoutePositionLayer
	} = useRouteLayers();

	const urlFilterId = searchParams.get('filterId') || '';
	const urlTrackerId = searchParams.get('trackerId') || '';
	const selectTrackersForMap = useMemo(() => {
		const emptySource: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
			type: 'FeatureCollection',
			features: []
		};

		return createSelector(
			(res) => res.currentData,
			(_res, stateFilter) => stateFilter,
			(data: EntityState<TTracker>, stateFilter: TTrackerClass[]) =>
				data ? getTrackersGeojson(selectAllTrackers(data), stateFilter) : emptySource
		);
	}, []);

	const selectWatchedCoordinates = useMemo(() => {
		return createSelector(
			(res) => res.currentData,
			(_res, trackerId) => trackerId,
			(data: EntityState<TTracker>, trackerId: string) => {
				if (!data || !trackerId) return emptyArr;
				const tracker = selectTrackerById(data, trackerId);
				const point: LngLatLike = [_.get(tracker, 'packet.LONGITUDE', 0), _.get(tracker, 'packet.LATITUDE', 0)];
				return point;
			}
		);
	}, []);

	const selectUrlTracker = useMemo(
		() =>
			createSelector(
				(res) => res.currentData,
				(_res, urlTrackerId) => urlTrackerId,
				(data: EntityState<TTracker>, urlTrackerId: string) => {
					if (!data || !urlTrackerId) return null;
					return selectTrackerById(data, urlTrackerId);
				}
			),
		[]
	);

	const { trackers, loading, watchedCoords, firstLoading, urlTracker } = useGetRealtimeQuery(urlFilterId, {
		pollingInterval: POLLING_INTERVAL,
		selectFromResult: (res) => ({
			firstLoading: res.isLoading,
			loading: res.isFetching,
			trackers: selectTrackersForMap(res, selectedStateFilter),
			watchedCoords: selectWatchedCoordinates(res, watchedTracker),
			urlTracker: selectUrlTracker(res, urlTrackerId)
		})
	});

	const selectFencesForMap = useMemo(() => {
		const emptySource: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
			type: 'FeatureCollection',
			features: []
		};

		return createSelector(
			(res) => res.currentData,
			(_res, geoFenceFilter) => geoFenceFilter,
			(data: IApiPagination<TGeoFence>, geoFenceFilter: string[]) =>
				data?.docs ? getGeoFencesGeojson(data.docs, geoFenceFilter) : emptySource
		);
	}, []);

	const { fences } = useGetGeoFencesQuery('?limit=0', {
		selectFromResult: (res) => ({
			fences: selectFencesForMap(res, selectedGeoFences)
		})
	});

	const prevFilter = usePrevious(urlFilterId);
	const prevFirstLoading = usePrevious(firstLoading);
	const prevUrlTracker = usePrevious(urlTracker);

	const handleMapInstance = useCallback(
		(instance: MapRef) => {
			if (!instance) return;
			dispatch(setMapInstance(instance));
		},
		[dispatch]
	);

	const onMapLoad = useCallback(() => {
		if (!mapRef) return;
		const uniqueImgs = _.uniq(_.values(TRACKER_STATE_POPUPS));
		uniqueImgs.forEach((imgName) => {
			if (!mapRef.hasImage(imgName)) {
				mapRef.loadImage(`assets/images/map-popups/${imgName}.png`, (err, img) => {
					if (err || !img) {
						throw err;
					}
					mapRef.addImage(imgName, img, {
						stretchX: [
							[25, 55],
							[85, 115]
						],
						stretchY: [[25, 100]],
						content: [25, 25, 115, 100],
						pixelRatio: 2
					});
				});
			}
		});
	}, [mapRef]);

	const onClick = (event: MapLayerMouseEvent) => {
		if (!mapRef || !event.features) return;
		const feature = event.features[0];
		const layerId = _.get(feature, 'layer.id', '');
		// if the user clicked at the cluster circle, zoom to see more clusters or single trackers
		if (layerId === clusterLayer.id) {
			const clusterId = feature?.properties?.cluster_id;
			const trackersSource = mapRef.getSource(TRACKERS_SRC.cluster);
			if (trackersSource) {
				// @ts-expect-error the lib type is wrong
				trackersSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
					if (err) {
						return;
					}

					mapRef.easeTo({
						// @ts-expect-error the lib type is wrong
						center: feature.geometry?.coordinates,
						zoom,
						duration: 500
					});
				});
			}
		}
		// if the user clicked at a single tracker
		else if (layerId === 'unclustered-point') {
			const trackerId = feature?.properties?._id;
			if (!trackerId) return;
			dispatch(setSelectedTrackerId(trackerId));
		}
		// if the user clicked at a route event
		else if (layerId === routePositionLayer.id) {
			dispatch(setEventPopup({ open: true, data: feature.properties as TPacket }));
		}
	};

	const onMouseEnter = (ev: MapLayerMouseEvent) => {
		if (!ev.features) return;
		const feature = ev.features[0];
		const layerId = _.get(feature, 'layer.id', '');
		if (layerId === routePositionLayer.id) {
			mapRef.getCanvas().style.cursor = 'pointer';
		}
	};

	const onMouseLeave = (ev: MapLayerMouseEvent) => {
		if (!ev.features) return;
		const feature = ev.features[0];
		const layerId = _.get(feature, 'layer.id', '');
		if (layerId === routePositionLayer.id) {
			mapRef.getCanvas().style.cursor = 'grab';
		}
	};

	const clearAllState = useCallback(() => {
		dispatch(setActionBarInitial());
		dispatch(setMapInitial());
		dispatch(setFiltersInitial());
		dispatch(setToolbarInitial());
		dispatch(setRouteInitial());
	}, [dispatch]);

	if (watchedCoords.length && mapRef) {
		mapRef.flyTo({ center: watchedCoords as LngLatLike, zoom: 16 });
	}

	if (prevFilter !== urlFilterId || (prevFirstLoading === true && !urlTracker)) {
		if (trackers.features.length && mapRef) {
			const pointsBbox = getBbox(trackers);
			mapRef.fitBounds(pointsBbox, {
				zoom: 8
			});
		}
	}

	useEffect(() => {
		if (urlTracker && !_.isEqual(prevUrlTracker, urlTracker)) {
			const point: LngLatLike = [
				_.get(urlTracker, 'packet.LONGITUDE', 0),
				_.get(urlTracker, 'packet.LATITUDE', 0)
			];
			mapRef.flyTo({ center: point as LngLatLike, zoom: 16 });
			dispatch(setSelectedTrackerId(urlTracker._id));
		}
	}, [urlTracker, prevUrlTracker, mapRef, dispatch]);

	useEffect(
		() => () => {
			clearAllState();
		},
		[clearAllState]
	);

	return (
		<>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					zIndex: 999,
					display: loading ? 'block' : 'none'
				}}
			>
				<LinearProgress color="secondary" />
			</Box>
			<Map
				mapLib={maplibreGl}
				initialViewState={{
					longitude: -69.7292625,
					latitude: -13.6562901,
					zoom: 4
				}}
				style={{ width: '100%', height: '100%' }}
				interactiveLayerIds={[clusterLayer.id, 'unclustered-point', routePositionLayer.id]}
				ref={handleMapInstance}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onLoad={onMapLoad}
				mapStyle={styleBasic}
				reuseMaps
			>
				<StyleControl />
				<MapToolbar />
				<ActionBar />
				<FullscreenControl position="top-left" />
				<NavigationControl position="top-left" />
				<Layer {...controlledOSMLayer} key="OSM" />
				<Layer {...controlledHybridLayer} key="hybrid" />
				{isClustered ? (
					<Source
						key={TRACKERS_SRC.cluster}
						id={TRACKERS_SRC.cluster}
						type="geojson"
						data={trackers}
						cluster={true}
						clusterMaxZoom={14}
						clusterRadius={50}
					/>
				) : (
					<Source key={TRACKERS_SRC.noCluster} id={TRACKERS_SRC.noCluster} type="geojson" data={trackers} />
				)}

				<Layer {...controlledClusterLayer} />
				<Layer {...controlledClusterCountLayer} />
				<Layer {...controlledUnclusteredPointLayer} />
				<Layer {...controlledPopupLayer} />

				<Source key="fences" id="fences" type="geojson" data={fences}>
					<Layer {...controlledFenceFillLayer} />
					<Layer {...controlledFenceLineLayer} />
				</Source>
				<Source key="a-tracker-route" id="route" type="geojson" data={currentRoute}>
					<Layer {...controlledRouteLine} />
					<Layer {...controlledRouteByPosition} />
					<Layer {...controlledRoutePositionLayer} />
					<Layer {...controlledRouteStartStopLayer} />
				</Source>
				<EventPopup />
				<TrackerPopup />
			</Map>
		</>
	);
}
