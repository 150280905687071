const en = {
	TRIPS_ADVANCED: 'Trip Details',
	DISTANCE: 'Distance Traveled',
	DURATION: 'Duration',
	MAX_SPEED: 'Maximum Speed',
	AVG_SPEED: 'Average Speed',
	START_ADDRESS: 'Start Address',
	END_ADDRESS: 'End Address',
	TRACKER_NAME: 'Tracker Name',
	COMPANY: 'Company',
	PLATE: 'Plate',
	BACK: 'Back',
	IGNITION_ON: 'Ignition',
	BATTERY: 'Battery',
	SPEED: 'Speed',
	SHARE: 'Share',
	UNKNOWN: 'Unknown',
	COPIED_TO_CLIPBOARD: 'Copied to clipboard',
	NO_DRIVER: 'No Driver',
	VEHICLE: 'Vehicle',
	CENTER: 'Center',
	GEO_FENCE: 'Geofence',
	FIX_MARKER: 'Fix vehicle',
	VELOCITY: 'Velocity'
};

export default en;
