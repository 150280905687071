const es = {
	TRIPS_ADVANCED: 'Detalles del Viaje',
	DISTANCE: 'Distancia Recorrida',
	DURATION: 'Duración',
	MAX_SPEED: 'Velocidad Máxima',
	AVG_SPEED: 'Velocidad Promedio',
	START_ADDRESS: 'Dirección de Inicio',
	END_ADDRESS: 'Dirección de Fin',
	TRACKER_NAME: 'Nombre del Rastreador',
	COMPANY: 'Empresa',
	PLATE: 'Placa',
	BACK: 'Volver',
	IGNITION_ON: 'Encendido',
	BATTERY: 'Batería',
	SPEED: 'Velocidad',
	SHARE: 'Compartir',
	UNKNOWN: 'Desconocido',
	COPIED_TO_CLIPBOARD: 'Copiado al portapapeles',
	NO_DRIVER: 'Sin Conductor',
	VEHICLE: 'Vehículo',
	CENTER: 'Centrar',
	GEO_FENCE: 'Geocerca',
	FIX_MARKER: 'Fijar vehículo',
	VELOCITY: 'Velocidad'
};

export default es;
