import { BusinessOutlined, DeviceHubOutlined } from '@mui/icons-material';
import { Chip, CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRACKER_STATE_COLORS, getTrackerClassText } from '../../mapHelpers';
import { TTrackerClass } from '../../types';
import { TrackerIcon } from '../tracker-card-layouts/ControlledIcons';

interface PopupHeaderProps {
	displayName: string;
	did?: number;
	trackerClass: TTrackerClass;
	iconName: string;
	loading?: boolean;
	company?: string;
}

export const PopupHeader: React.FC<PopupHeaderProps> = ({
	displayName,
	did,
	iconName,
	trackerClass,
	loading,
	company
}) => {
	const { t } = useTranslation('mapPage');

	return (
		<Stack direction="row" alignItems="start" gap={2} width="100%" p={2}>
			<TrackerIcon iconName={iconName} trackerClass={trackerClass} isDarkMode={false} />
			<Stack maxWidth="50%">
				<Stack direction="row" alignItems="center" gap={1}>
					<Typography fontWeight={600} fontSize={18} lineHeight="24px" textOverflow="ellipsis" noWrap>
						{displayName}
					</Typography>
					{loading && <CircularProgress size={12} />}
				</Stack>

				{did && (
					<Typography fontWeight={600} fontSize={12} color="text.disabled">
						<DeviceHubOutlined sx={{ fontSize: '14px', marginRight: '8px' }} />
						{did}
					</Typography>
				)}

				{company && (
					<Typography fontWeight={600} fontSize={12} color="text.disabled">
						<BusinessOutlined sx={{ fontSize: '14px', marginRight: '8px' }} />
						{company}
					</Typography>
				)}
			</Stack>
			<Chip
				label={getTrackerClassText(trackerClass, t)}
				variant="filled"
				sx={(theme) => ({
					ml: 'auto',
					backgroundColor: TRACKER_STATE_COLORS[trackerClass],
					borderRadius: '8px',
					minWidth: '158px',
					color: theme.palette.getContrastText(TRACKER_STATE_COLORS[trackerClass]),
					fontWeight: 600,
					fontSize: '12px'
				})}
			/>
		</Stack>
	);
};
