import MapPopup from 'app/shared-components/MapPopup';
import { realtimeAdapter, realtimeSlice, selectTrackerById } from 'app/store/api/trackingSlice';
import { useAppDispatch, useAppSelector } from 'app/store/index';
import { clearSelectedTrackerId, selectSelectedTrackerId } from 'app/store/map/mapSlice';

import { Divider, IconButton, Stack, Tooltip } from '@mui/material';
import { goToStreetView } from 'app/main/reports/historyNew/helper';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TFunction, useTranslation } from 'react-i18next';
import formatBattery from 'src/utils/formatBattery';
import { getTrackerClass } from '../../mapHelpers';
import { TTracker } from '../../types';
import { PopupHeader } from './PopupHeader';

import {
	AccessAlarmOutlined,
	DocumentScannerOutlined,
	InfoOutlined,
	PersonOutline,
	ShareLocationOutlined
} from '@mui/icons-material';
import CopyButton from 'app/shared-components/Buttons/CopyButton';
import { Information } from '../../../../shared-components/Information';

import { openDialog } from 'app/store/fuse/dialogSlice';
import { TrackerCommandStatus } from 'app/types/tracker-command-status.types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import userHasPermission from 'src/utils/useHasPermission';
import useBlockTracker from '../../hooks/useBlockTracker';
import useTracker from '../../hooks/useTracker';
import useTrackerGeoLocation from '../../hooks/useTrackerGeoLocation';
import TrackerSettings from '../tracker-settings/TrackerSettings';
import { Address } from './Address';
import BlockComponent from './BlockComponent';
import { PublicLink } from './PublicLink';
import { TrackerData } from './TrackerData';

const getTrackerName = (data: TTracker) => {
	const vehicleName = _.get(data, 'vehicle.name', '');
	const trackerName = _.get(data, 'name', '');
	const did = _.get(data, 'did', '');
	return vehicleName || trackerName || _.toString(did);
};

export const getCopyString = (tracker: TTracker, coords: number[], address: string, t: TFunction) => {
	const voltage = t(formatBattery(_.get(tracker, 'packet.VEHICLE_VOLTAGE', 0)));
	return `
${t('DEVICE')} ${_.get(tracker, 'did', 0)};
${t('VOLTAGE')}: ${voltage};
${t('DATE')}: ${dayjs(_.get(tracker, 'packet.SERVER_TIME', Date())).format('DD/MM/YYYY - HH:mm:ss')};
${t('LOCATION')}: ${address};
${t('LAST_POSITION')}: ${coords[1]}, ${coords[0]}
`;
};

const setPending = (filterId: string, trackerId: string, isBlocked: boolean) =>
	realtimeSlice.util.updateQueryData('getRealtime', filterId, (draft) => {
		const tracker = selectTrackerById(draft, trackerId);
		const blockStatus = isBlocked ? 'UNBLOCK_STATUS' : 'BLOCK_STATUS';
		const blockRequest = isBlocked ? 'unblock' : 'block';
		realtimeAdapter.updateOne(draft, {
			id: trackerId,
			changes: {
				packet: {
					...tracker?.packet,
					[blockStatus]: TrackerCommandStatus.Scheduling,
					[blockRequest]: true,
					IS_WAITING_BLOCK: true
				}
			}
		});
	});

export const TrackerPopup: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('mapPage');
	const trackerId = useAppSelector(selectSelectedTrackerId);
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const filterId = searchParams.get('filterId') || '';

	const { trackerDID, currentTracker, isRealtimeBlocked, isRealtimeWaiting, isRealtimeLoading, isFetching } =
		useTracker(trackerId);

	const { isBlockCommandLoading, lastBlockStatus, lastBlockCommand } = useBlockTracker(trackerDID);

	const lastCommandName = _.get(lastBlockCommand, 'command.commandName', '');
	const userHasBlockPermission = userHasPermission('fleet.tracker.block');
	const canBeBlocked = _.get(currentTracker, 'canBeBlocked', false);
	const hasBatteryStatus = _.has(currentTracker, 'packet.BATTERY_STATUS');
	const batteryStatus = _.get(currentTracker, 'packet.BATTERY_STATUS');

	const coords: [number, number] = [
		_.get(currentTracker, 'packet.LONGITUDE', 0),
		_.get(currentTracker, 'packet.LATITUDE', 0)
	];

	const { address, isLoadingAddress, hasCoords } = useTrackerGeoLocation(coords);

	const copyString = getCopyString(currentTracker, coords, address, t);
	const driver = _.get(currentTracker, 'iButton.driver.name', null);
	const serialCode = _.get(currentTracker, 'packet.SERIAL_CODE', null);
	const displayDriver = driver || serialCode;
	const company = _.get(currentTracker, 'company.name', null);

	const handleClose = () => dispatch(clearSelectedTrackerId());

	const handleGoogleMap = () => {
		if (hasCoords) goToStreetView(coords[1], coords[0]);
	};

	const handleViewTrackerSettings = () => {
		dispatch(
			openDialog({
				children: <TrackerSettings trackerDID={trackerDID} />
			})
		);
	};

	const handleBlockOrUnblockSuccess = () => {
		dispatch(setPending(filterId, trackerId, isRealtimeBlocked));
	};

	const handleViewAnalyticReport = () => {
		const company = _.get(currentTracker, 'company._id', null);
		const tracker = _.get(currentTracker, '_id', null);
		const start = dayjs().subtract(1, 'day').toISOString();
		const end = dayjs().toISOString();
		const page = 1;
		const limit = 100;

		if (!company || !tracker) return;

		const searchParams = new URLSearchParams({
			company: company,
			tracker: tracker,
			start: start,
			end: end,
			page: page.toString(),
			limit: limit.toString()
		});

		navigate(`/reports/analytic?${searchParams.toString()}`);
	};

	if (!hasCoords || !trackerId) return undefined;

	return (
		<MapPopup maxWidth="540" longitude={coords[0]} latitude={coords[1]} onClose={handleClose} closeButton={false}>
			<PopupHeader
				company={company}
				did={trackerDID}
				displayName={getTrackerName(currentTracker)}
				iconName={_.get(currentTracker, 'vehicle.type.icon')}
				trackerClass={getTrackerClass(currentTracker)}
				loading={isFetching}
			/>
			<Divider />
			<Stack direction="row" alignItems="start" p={2} gap={4}>
				<Address address={address} loading={isLoadingAddress} />
				<Stack direction="row">
					<Tooltip title={t('TO_GOOGLE_MAPS')}>
						<IconButton size="small" color="secondary" onClick={handleGoogleMap}>
							<ShareLocationOutlined />
						</IconButton>
					</Tooltip>
					<CopyButton mode="text" data={copyString} tooltipText={t('COPY_INFO')} />
					<Tooltip title={t('TRACKER_SETTINGS')}>
						<IconButton size="small" color="secondary" onClick={handleViewTrackerSettings}>
							<InfoOutlined />
						</IconButton>
					</Tooltip>{' '}
					<Tooltip title={t('VIEW_ANALYTIC_REPORT')}>
						<IconButton size="small" color="secondary" onClick={handleViewAnalyticReport}>
							<DocumentScannerOutlined />
						</IconButton>
					</Tooltip>
					<PublicLink vehicleId={_.get(currentTracker, 'vehicle._id', '')} />
				</Stack>
			</Stack>
			<Stack direction="column" px={2} pb={2}>
				<Information
					icon={AccessAlarmOutlined}
					label={t('LAST_GPS_TIME')}
					value={dayjs(_.get(currentTracker, 'packet.GPS_TIME', Date())).format('DD/MM/YYYY - HH:mm:ss')}
				/>

				{displayDriver && <Information icon={PersonOutline} label={t('DRIVER')} value={displayDriver} />}

				{lastBlockStatus && (
					<Information
						loading={isBlockCommandLoading}
						label={t('LAST_BLOCK_COMMAND', {
							command: t(lastCommandName)?.toLowerCase()
						})}
						value={t(lastBlockStatus)}
					/>
				)}
			</Stack>
			<TrackerData
				hasBatteryStatus={hasBatteryStatus}
				batteryStatus={batteryStatus}
				vehicleVoltage={_.get(currentTracker, 'packet.VEHICLE_VOLTAGE', 0)}
				ignition={_.get(currentTracker, 'packet.IGNITION_ON', false)}
				speed={_.get(currentTracker, 'packet.SPEED', 0)}
				isBlocked={isRealtimeBlocked}
				canBeBlocked={canBeBlocked}
				isRealtimeWaiting={isRealtimeWaiting}
				blockComponent={
					userHasBlockPermission &&
					canBeBlocked && (
						<BlockComponent
							isWaiting={isRealtimeWaiting}
							isRealtimeLoading={isRealtimeLoading}
							onSuccess={handleBlockOrUnblockSuccess}
							trackerDID={trackerDID}
							isBlocked={isRealtimeBlocked}
							t={t}
						/>
					)
				}
			/>
		</MapPopup>
	);
};
