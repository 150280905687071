const en = {
	GEO_FENCE_REPORT: 'Geo Fence Report',
	ID: 'ID',
	DATE: 'Date',
	TRACKER: 'Tracker',
	VEHICLE: 'Vehicle',
	GEO_FENCE: 'Geo Fence',
	TYPE: 'Type',
	COMPANY: 'Company',
	IN: 'In',
	OUT: 'Out',
	CLEAR_FILTERS: 'Clear filters',

	SEARCH: 'Search',
	START_DATE: 'Start Date',
	END_DATE: 'End Date',
	SHOW_FILTERS: 'Show Filters',
	NO_OPTIONS_FOR_COMPANY: 'No options for company {{company}}',
	DOWNLOAD: 'Download report',
	DRIVER: 'Driver',
	TABLE_CONFIG: 'Table configuration'
};

export default en;
