import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { TDriver } from 'app/main/ibutton/types';
import { TMonitoringEvent } from 'app/main/monitoring-event/types';
import { TSimcard } from 'app/main/simcards/types';
import { TTracker } from 'app/main/trackers/manage/types';
import { TUser } from 'app/main/users/types';
import storageService from 'app/services/storage';
import { TCompany, TFleet, TVehicle } from 'src/app/main/map/types';
import { RootState } from '..';
import { IAddress, IApiPagination, TPlate, TrackerType, TVehicleType } from './types';

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions
) => {
	const state = api.getState() as RootState;
	const baseUrl = state.configuration.baseUrl || import.meta.env.VITE_API_URL;

	const baseQuery = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers, { endpoint,  }) => {
			if (endpoint === 'loginUser') return headers;
			const token = storageService.get('token');

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			// 9s 12s
			// headers.set('Content-Encoding', 'gzip');
			// headers.set('Accept-Encoding', 'gzip');
			return headers;
		}
	});

	return baseQuery(args, api, extraOptions);
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions
) => {
	const result = await dynamicBaseQuery(args, api, extraOptions);
	if (result.error && result.error.status === 401) {
		storageService.remove('token');
	}
	return result;
};
export const apiSlice = createApi({
	reducerPath: 'api',
	tagTypes: [
		'Realtime',
		'Settings',
		'User',
		'Company',
		'Fleet',
		'Vehicle',
		'ScheduleCommand',
		'ScheduledCommandHistory',
		'Notifications',
		'GeoFence',
		'TrackerCommands',
		'Trackers',
		'Simcards',
		'Ibuttons',
		'Permissions',
		'Vehicles',
		'Ibuttons',
		'Session',
		'Profile',
		'Urls',
		'Contact',
		'Drivers',
		'TrackerType',
		'Ruler',
		'Trips',
		'History',
		'MonitoringEvents',
		'SimcardBrands',
		'Ships',
		'MonitoringAlerts',
		'MonitoringSettings',
		'Plate',
		'Maintenance',
		'MaintenanceItem',
		'Course',
		'Lessons',
		'CourseCategory',
		'RealtimeWithFilters',
		'AccessPeriods',
		'TrackersByCompany',
		'BlockCommandHistory',
		'Realtime',
		'FuelSupply',
		'FuelEstablishment',
		'ScheduleCommandWithHistory'
	],
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getVehicleInfoByPlate: builder.query<TPlate, string>({
			query: (plate = '') => `/plate/${plate}`,
			providesTags: ['Plate']
		}),

		getCompanies: builder.query<IApiPagination<TCompany>, string>({
			query: (queryString = '') => `/company?limit=0&${queryString}`
		}),
		getFleets: builder.query<IApiPagination<TFleet>, string>({
			query: (queryString = '') => `/fleet?limit=0&${queryString}`,
			providesTags: ['Fleet']
		}),
		getVehicles: builder.query<IApiPagination<TVehicle>, string>({
			query: (queryString = '') => `/vehicle?limit=0&${queryString}`
		}),
		getTrackerParsers: builder.query<IApiPagination<TrackerType>, string>({
			query: () => `/tracker-type?limit=0`,
			providesTags: ['TrackerCommands']
		}),
		getAddress: builder.query<IAddress[], string>({
			query: (address) => `/address?search=${address}`
		}),
		uploadUserAvatar: builder.mutation({
			query: (formData: FormData) => ({
				url: '/upload/images/avatar',
				method: 'POST',
				body: formData
			}),
			invalidatesTags: ['User']
		}),
		getTrackers: builder.query<IApiPagination<TTracker>, string>({
			query: (queryString = '') => `/tracker?limit=0&${queryString}`,
			providesTags: ['Trackers']
		}),
		getDrivers: builder.query<IApiPagination<TDriver>, string>({
			query: (queryString = '') => `/driver?limit=0&${queryString}`
		}),
		getVehicleTypes: builder.query<IApiPagination<TVehicleType>, string>({
			query: (queryString = '') => `/vehicle-type?limit=0&${queryString}`
		}),
		uploadBanner: builder.mutation({
			query: (formData: FormData) => ({
				url: '/upload/images/banner',
				method: 'POST',
				body: formData
			}),
			invalidatesTags: ['Urls']
		}),
		uploadLogo: builder.mutation({
			query: (formData: FormData) => ({
				url: '/upload/images/logo?w=400&h=400',
				method: 'POST',
				body: formData
			}),
			invalidatesTags: ['Urls']
		}),
		getUsers: builder.query<IApiPagination<TUser>, string>({
			query: (queryString = '') => `/user?limit=0&${queryString}`
		}),
		getTrackerEvents: builder.query<IApiPagination<TMonitoringEvent>, string>({
			query: (queryString = '') => `/tracker-events?limit=0&${queryString}`,
			providesTags: ['MonitoringEvents']
		}),
		getSimcards: builder.query<IApiPagination<TSimcard>, string>({
			query: (queryString = '') => `/simcard?limit=0&${queryString}`,
			providesTags: ['Simcards']
		})
	})
});

export const {
	useGetCompaniesQuery,
	useGetFleetsQuery,
	useGetVehiclesQuery,
	useGetTrackerParsersQuery,
	useGetAddressQuery,
	useUploadUserAvatarMutation,
	useGetTrackersQuery,
	useGetDriversQuery,
	useGetVehicleTypesQuery,
	useUploadBannerMutation,
	useUploadLogoMutation,
	useGetUsersQuery,
	useGetTrackerEventsQuery,
	useGetSimcardsQuery,
	useLazyGetVehicleInfoByPlateQuery
} = apiSlice;
