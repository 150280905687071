const sp = {
	GEO_FENCE_REPORT: 'Informe de cerca virtual',
	ID: 'ID',
	DATE: 'Fecha',
	TRACKER: 'Rastreador',
	VEHICLE: 'Vehículo',
	GEO_FENCE: 'Cerca virtual',
	TYPE: 'Tipo',
	COMPANY: 'Empresa',
	IN: 'Entrada',
	OUT: 'Salida',
	SEARCH: 'Buscar',
	START_DATE: 'Fecha de inicio',
	END_DATE: 'Fecha final',
	SHOW_FILTERS: 'Mostrar filtros',
	NO_OPTIONS_FOR_COMPANY: 'No hay opciones para la empresa {{company}}',
	DOWNLOAD: 'Descargar informe',
	DRIVER: 'Conductor',
	TABLE_CONFIG: 'Configuración de la tabla'
};

export default sp;
