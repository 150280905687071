import { TrackerCommandStatus } from 'app/types/tracker-command-status.types';

const locale = {
	// Required Fields

	COMPANY_REQUIRED: 'The company field is required',
	DID_MUST_BE_NUMBER: 'The DID field must be a number',
	TRACKER_TYPE_REQUIRED: 'The tracker type field is required',
	DID_REQUIRED: 'The DID field is required',
	NAME_REQUIRED: 'The name field is required',
	WRONG_STATUS: 'The status field is wrong',
	SELECT_COMPANY_FIRST: 'Select a company first',

	// Common

	NOTES: 'Notes',
	VEHICLE: 'Vehicle',
	NAME: 'Name',
	TRACKER_TYPE: 'Tracker type',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	COMPANY: 'Company',
	DESC: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	CONFIRM: 'Confirm',
	SIMCARD: 'Simcard',
	CAN_BE_BLOCKED: 'Can be blocked?',
	CAN_BE_BLOQUED_TOOLTIP: 'This indicates if the tracker can be configured to lock the vehicle',

	INFO_TRACKER: 'Last Package Information',
	EVENT_NAME: 'Event Name',
	SPEED: 'Speed',
	SERVER_TIME: 'Server Time',
	GPS_TIME: 'GPS Time',
	IGNITION_ON: 'Ignition State',
	VEHICLE_VOLTAGE: 'Vehicle Voltage',
	BATTERY: 'Battery Voltage',
	ODOMETER: 'Odometer',
	UNAVAILABLE: 'Unavailable',
	ON: 'On',
	OFF: 'Off',
	LONGITUDE: 'Longitude',
	LATITUDE: 'Latitude',

	// Header

	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	TITLE: 'Trackers',
	DOWNLOAD_ALL: 'Download all',
	ADD_USER: 'Add Tracker',
	SHOW_FILTERS: 'Show filters',
	CONFIG_TABLE: 'Configure table',
	NO_SIMCARDS: 'No simcards found',
	NO_VEHICLES: 'No vehicles found',
	FILTER: 'Filter',
	// Sidebar

	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	SELECTED_ITEMS: 'Selected items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export selected items',
	DELETE_SELECTED: 'Delete selected items',
	EDIT_SELECTED: 'Edit selected items',
	EDIT_TITLE: 'Edit tracker',
	EDIT_INFO: 'Edit trackers',
	DELETE_INFO: 'Delete trackers',
	DOWNLOAD_INFO: 'Download selected trackers',
	EDIT_MULTIPLE_INFO: 'Edit selected trackers',
	DELETE_MULTIPLE_INFO: 'Delete selected trackers',
	SELECTED_ITENS: '{{n}} selected items',

	// Table

	OF: 'of',
	ROWS_PER_PAGE: 'Items per page',
	NO_TRACKERS_FOUND: 'No trackers found',

	// Modal

	EDIT_CONFIRMATION: 'Are you sure you want to edit this tracker?',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected trackers?',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	DELETE_MODAL_TITLE: 'Delete user',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete the tracker',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} trackers',
	WARNING: 'Warning',
	TRACKER_STATUS_WARNING:
		'If the device status is changed to "In stock" and it is associated with a vehicle, the vehicle will be disassociated from the tracker.',
	TRACKER_STATUS_WARNING_2: 'This action cannot be undone. Are you sure you want to do this?',

	// Permissions

	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',

	// Requests

	CREATE_SUCCESS: 'Tracker successfully added',
	CREATE_ERROR: 'Error adding user',
	EDIT_SUCCESS: 'Tracker successfully updated',
	EDIT_ERROR: 'Error updating user',
	DELETE_SUCCESS: 'Tracker successfully deleted',
	DELETE_ERROR: 'Error deleting user',
	DOWNLOAD_ERROR: 'Error downloading file',
	EDIT_MULTIPLE_SUCCESS: 'Trackers successfully updated',
	EDIT_MULTIPLE_ERROR: 'Error updating trackers',
	TRACKER_ALREADY_EXISTS_DELETED: 'Tracker already exists and is deleted',
	// Tracker Status

	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
	LOST: 'Lost',
	DAMAGED: 'Damaged',
	STOCK: 'Stock',
	STATUS: 'Device status',

	// Bulk

	SHOW_INVALID_ROWS: 'Show invalid rows',
	CLEAR_TABLE: 'Clear table',
	CREATING: 'Creating',
	DESCRIPTION_HEADER:
		'Here you can create tracker in bulk by uploading a XLSX file with trackers data, or download the template and fill it with the tracker data you want to create. You can open the XLSX file with Excel or another spreadsheet editor.',
	DOWNLOAD_TEMPLATE: 'Download template.',
	IMPORT: 'Import',
	DROPZONE_TEXT: 'Drag and drop the XLSX file here or click to select.',
	REMOVE_ROW: 'Remove rows',
	ERROR: 'Error',
	SUCCESS: 'Success',
	INVALIDS: 'Invalids',
	VALIDS: 'Valids',
	MASSIVE_CREATE_SUCCESS: 'Trackers successfully created, check the details in the summary icon.',

	// Restore
	DELETED: 'Deleted',
	SHOW_DELETED: 'Deleted items',
	RESTORE_INFO: 'Restore tracker',
	RESTORE_MODAL_TITLE: 'Restore tracker',
	RESTORE_MODAL_DESCRIPTION: 'Are you sure you want to restore tracker: {{name}}',
	RESTORE_SUCCESS: 'Tracker restored successfully',
	RESTORE_ERROR: 'Error restoring tracker',
	DELETED_AT: 'Deleted in',
	DELETED_BY: 'Deleted by',
	SATELLITES: 'Satellites',
	COMPANY_MUST_BE_NUMBER: 'The company field must be a number of CPF or CNPJ, DO NOT USE SPECIAL CHARACTERS',
	PLATE_LENGTH: 'The vehicle field must have 7 characters, representing the plate number',

	SEND_COMMAND: 'Send Command',
	COMMAND_IDENTITY: 'Command Identity',
	COMMAND_IDENTITY_INFO:
		'This is the identity of the command that will be sent to the tracker, used to generate the history of sent commands.',
	DID_INFO: 'This field is automatically filled with the tracker number, no need to fill it in.',
	COMMAND_IDENTITY_REQUIRED: 'The command identity field is required',
	VALUE_REQUIRED: 'The field is required',
	LAST_SENT_COMMAND: 'Last sent command:',
	[TrackerCommandStatus.Pending]: 'Pending',
	[TrackerCommandStatus.Canceled]: 'Canceled',
	[TrackerCommandStatus.Queued]: 'Queued',
	[TrackerCommandStatus.Sent]: 'Sent',
	[TrackerCommandStatus.Confirmed]: 'Confirmed',
	[TrackerCommandStatus.Scheduling]: 'Scheduling',
	LAST_SENT_COMMAND_SUCCESS: 'Command successfully registered!',
	LAST_SENT_COMMAND_SUCCESS_INFO: 'The command sending may take a few minutes, monitor the sending status below.',
	LAST_SENT_COMMAND_ERROR: 'Error sending command',
	LAST_SENT_COMMAND_ERROR_INFO: 'There was an error sending the command, check the tracker availability.',
	RESPONSE: 'Tracker response:',
	LAST_SENT_COMMANDS: 'Last sent commands.',
	LAST_SENT_COMMANDS_DESCRIPTION: 'Here you can see the last commands sent to the tracker, click to see details.',
	SEND_COMMAND_TO: 'Send command to',
	CLEAR_FILTERS: 'Clear filters'
};
export default locale;
