import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { IconButton, Typography } from '@mui/material';
import { TMonitoringEvent } from 'app/main/monitoring-event/types';
import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import _ from 'lodash';
import type { TFunction } from 'react-i18next';
import formatBattery from 'src/utils/formatBattery';
import { decodeEventTypeName, getAddress, getGpsStatusIcon, getOdometerValue, goToStreetView } from './helper';
import { TColumnsNames, TTableColumn } from './types';

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('PLATE'), columnId: 'vehicle.plate' },
	{ name: t('DID'), columnId: 'DID' },
	{ name: t('ADDRESS'), columnId: 'address' },
	{ name: t('ODOMETER'), columnId: 'MILEAGE' },
	{ name: t('SERVER_TIME'), columnId: 'SERVER_TIME' },
	{ name: t('GPS_TIME'), columnId: 'GPS_TIME' },
	{ name: t('SPEED'), columnId: 'SPEED' },
	{ name: t('IGNITION_ON'), columnId: 'IGNITION_ON' },
	{ name: t('IS_GPS_RUNNING'), columnId: 'IS_GPS_RUNNING' },
	{ name: t('GOOGLE_MAPS'), columnId: 'streetView' },
	{ name: t('SEQUENCE'), columnId: 'SEQUENCE' },
	{ name: t('PARSER'), columnId: 'PARSER' },
	{ name: t('EVENT_TYPE'), columnId: 'EVENT_TYPE' },
	{ name: t('ALTITUDE'), columnId: 'ALTITUDE' },
	{ name: t('SATELLITES'), columnId: 'SATELLITES' },
	{ name: t('RXLEV'), columnId: 'RXLEV' },
	{ name: t('LATITUDE'), columnId: 'LATITUDE' },
	{ name: t('LONGITUDE'), columnId: 'LONGITUDE' },
	{ name: t('DRIVER'), columnId: 'SERIAL_CODE' },
	{ name: t('BATTERY'), columnId: 'BATTERY' }
	// { name: t('HOURMETER'), columnId: 'HOURMETER' }
];

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('PLATE'), columnId: 'vehicle.plate' },
	{ name: t('DID'), columnId: 'DID' },
	{ name: t('ADDRESS'), columnId: 'address' },
	{ name: t('DRIVER'), columnId: 'driver.name' }
];

export const columnsSearchKeys = [
	'vehicle.plate',
	'DID',
	'address',
	'MILEAGE',
	'GPS_TIME',
	'SPEED',
	'IGNITION_ON',
	'IS_GPS_RUNNING',
	'driver.name'
];

export const availableSortKeys = [
	'vehicle.plate',
	'DID',
	'address',
	'MILEAGE',
	'GPS_TIME',
	'SPEED',
	'IGNITION_ON',
	'IS_GPS_RUNNING',
	'SEQUENCE',
	'PARSER',
	'EVENT_TYPE',
	'ALTITUDE',
	'SATELLITES',
	'RXLEV',
	'LATITUDE',
	'LONGITUDE',
	'BATTERY',
	// 'HOURMETER',
	'driver.name'
];

export const defaultVisibleColumns = [
	'DID',
	'address',
	'GPS_TIME',
	'SPEED',
	'IGNITION_ON',
	'streetView',
	'EVENT_TYPE',
	'ALTITUDE',
	'SATELLITES',
	'RXLEV',
	'LATITUDE',
	'LONGITUDE',
	'BATTERY',
	// 'HOURMETER',
	'PARSER',
	'SEQUENCE',
	'SERIAL_CODE',
	'SERVER_TIME'
];

export const isColumnOmitted = (columnId: string, allColumns: string[]) =>
	allColumns ? !allColumns.includes(columnId) : false;

export const getColumns = (
	t: TFunction,
	filteredColumns: string[],
	trackerEvents: TMonitoringEvent[]
): TTableColumn[] => [
	{
		minWidth: '200px',
		id: 'DID',
		name: <ColumnName name={t('DID')} icon="material-outline:device_hub" />,
		selector: (row) => row?.DID,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('DID', filteredColumns)
	},
	{
		id: 'vehicle.plate',
		name: <ColumnName name={t('PLATE')} icon="material-outline:view_day" />,
		selector: (row) => row?.vehicle?.plate,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('vehicle.plate', filteredColumns)
	},
	{
		minWidth: '360px',
		id: 'address',
		name: <ColumnName name={t('ADDRESS')} icon="material-outline:location_on" />,
		selector: (row) => getAddress(row),
		reorder: true,
		wrap: true,
		grow: 2,
		omit: isColumnOmitted('address', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'MILEAGE',
		name: <ColumnName name={t('ODOMETER')} icon="material-outline:av_timer" />,
		selector: (row) => row?.MILEAGE && getOdometerValue(row?.MILEAGE),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('MILEAGE', filteredColumns)
	},
	// {
	// 	minWidth: '200px',
	// 	id: 'HOURMETER',
	// 	name: <ColumnName name={t('HOURMETER')} icon="material-outline:timer" />,
	// 	selector: (row) => t('HOURS_NUM', { n: (row?.HOURMETER ?? 0).toLocaleString('pt-BR') }) satisfies string,
	// 	sortable: true,
	// 	reorder: true,
	// 	omit: isColumnOmitted('HOURMETER', filteredColumns)
	// },
	{
		minWidth: '200px',
		id: 'GPS_TIME',
		name: <ColumnName name={t('GPS_TIME')} icon="material-outline:access_time" />,
		selector: (row) => row?.GPS_TIME && dayjs(row.GPS_TIME).format('DD/MM/YYYY HH:mm'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('GPS_TIME', filteredColumns)
	},
	{
		minWidth: '260px',
		id: 'SERVER_TIME',
		name: <ColumnName name={t('SERVER_TIME')} icon="material-outline:access_time" />,
		selector: (row) => row?.SERVER_TIME && dayjs(row.SERVER_TIME).format('DD/MM/YYYY HH:mm'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('SERVER_TIME', filteredColumns)
	},
	{
		minWidth: '100px',
		id: 'SPEED',
		name: <ColumnName name={t('SPEED')} icon="material-outline:speed" />,
		cell: (row) => typeof row?.SPEED === 'number' && `${row.SPEED} km/h`,
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('SPEED', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'IGNITION_ON',
		name: <ColumnName name={t('IGNITION_ON')} icon="material-outline:power" />,
		cell: (row) => (
			<div className="flex items-center gap-8">
				<Typography>{row.IGNITION_ON ? t('ON') : t('OFF')}</Typography>
				<FuseSvgIcon size={16} className={row.IGNITION_ON ? 'text-green' : 'text-red'}>
					material-outline:bolt
				</FuseSvgIcon>
			</div>
		),
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('IGNITION_ON', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'IS_GPS_RUNNING',
		name: <ColumnName name={t('IS_GPS_RUNNING')} icon="material-outline:location_searching" />,
		cell: (row) => getGpsStatusIcon(row.IS_GPS_RUNNING, row.GPS_FIXED),
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('IS_GPS_RUNNING', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'SEQUENCE',
		name: <ColumnName name={t('SEQUENCE')} icon="material-outline:animation" />,
		cell: (row) => row?.SEQUENCE,
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('SEQUENCE', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'PARSER',
		name: <ColumnName name={t('PARSER')} icon="material-outline:ballot" />,
		cell: (row) => row?.PARSER,
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('PARSER', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'EVENT_TYPE',
		name: <ColumnName name={t('EVENT_TYPE')} icon="material-outline:all_out" />,
		selector: (row) => decodeEventTypeName(row.EVENT_TYPE, trackerEvents, t),
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('EVENT_TYPE', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'SERIAL_CODE',
		name: <ColumnName name={t('DRIVER')} icon="material-outline:person" />,
		selector: (row) => _.get(row, 'driver.name', _.get(row, 'trip.driver.name', _.get(row, 'SERIAL_CODE', ''))),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('SERIAL_CODE', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'BATTERY',
		name: <ColumnName name={t('BATTERY')} icon="material-outline:battery_horizontal" />,
		cell: (row) => t(formatBattery(row.VEHICLE_VOLTAGE)),
		sortable: false,
		reorder: true,
		center: true,
		omit: isColumnOmitted('BATTERY', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'ALTITUDE',
		name: <ColumnName name={t('ALTITUDE')} icon="material-outline:show_chart" />,
		cell: (row) => `${row?.ALTITUDE ?? 0} m`,
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('ALTITUDE', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'SATELLITES',
		name: <ColumnName name={t('SATELLITES')} icon="material-outline:satellite_alt" />,
		cell: (row) => row?.SATELLITES ?? 0,
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('SATELLITES', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'RXLEV',
		name: <ColumnName name={t('RXLEV')} icon="material-outline:sensors" />,
		cell: (row) => `${row?.RXLEV ?? 0} dbm`,
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('RXLEV', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'LATITUDE',
		name: <ColumnName name={t('LATITUDE')} icon="material-outline:public" />,
		cell: (row) => row?.LATITUDE,
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('LATITUDE', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'LONGITUDE',
		name: <ColumnName name={t('LONGITUDE')} icon="material-outline:public" />,
		cell: (row) => row?.LONGITUDE,
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('LONGITUDE', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'streetView',
		name: <ColumnName name={t('GOOGLE_MAPS')} icon="material-outline:streetview" />,
		button: true,
		cell: (row) => (
			<IconButton onClick={() => goToStreetView(row.LATITUDE, row.LONGITUDE)} color="secondary">
				<FuseSvgIcon>material-outline:streetview</FuseSvgIcon>
			</IconButton>
		),
		reorder: false,
		center: true,
		omit: isColumnOmitted('streetView', filteredColumns)
	}
];
