const pt = {
	GEO_FENCE_REPORT: 'Relatório de cerca virtual',
	ID: 'ID',
	DATE: 'Data',
	TRACKER: 'Rastreador',
	VEHICLE: 'Veículo',
	GEO_FENCE: 'Cerca virtual',
	TYPE: 'Tipo',
	COMPANY: 'Empresa',
	IN: 'Entrada',
	OUT: 'Saída',
	CLEAR_FILTERS: 'Limpar filtros',
	SEARCH: 'Pesquisar',
	START_DATE: 'Data de início',
	END_DATE: 'Data final',
	SHOW_FILTERS: 'Mostrar filtros',
	NO_OPTIONS_FOR_COMPANY: 'Nenhuma opção encontrada para a empresa {{company}}',
	DOWNLOAD: 'Baixar relatório',
	DRIVER: 'Motorista',
	TABLE_CONFIG: 'Configuração da tabela'
};
export default pt;
