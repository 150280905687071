import i18next from 'i18next';

import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

import Map from './Map';

i18next.addResourceBundle('en', 'mapPage', en);
i18next.addResourceBundle('sp', 'mapPage', sp);
i18next.addResourceBundle('pt', 'mapPage', pt);

export const DRAWER_WIDTH = 455;
export const POLLING_INTERVAL = 30000;

const MapConfig = {
	layout: {
		config: {
			settingsPanel: {
				display: false
			}
		}
	},
	routes: [
		{
			path: 'realtime',
			element: <Map />
		}
	]
};

export default MapConfig;
