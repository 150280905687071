import { TrackerCommandStatus } from 'app/types/tracker-command-status.types';

const locale = {
	// Campos Obligatorios

	COMPANY_REQUIRED: 'El campo de empresa es obligatorio',
	DID_MUST_BE_NUMBER: 'El campo DID debe ser un número',
	TRACKER_TYPE_REQUIRED: 'El campo de tipo de rastreador es obligatorio',
	DID_REQUIRED: 'El campo DID es obligatorio',
	NAME_REQUIRED: 'El campo de nombre es obligatorio',
	WRONG_STATUS: 'El campo de estado está equivocado',
	SELECT_COMPANY_FIRST: 'Seleccione una empresa primero',

	// Comunes

	NOTES: 'Notas',
	VEHICLE: 'Vehículo',
	NAME: 'Nombre',
	TRACKER_TYPE: 'Tipo de rastreador',
	ADD: 'agregar',
	YES: 'Sí',
	NO: 'No',
	COMPANIES: 'Empresas',
	DESC: 'Descripción',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	CONFIRM: 'Confirmar',
	SIMCARD: 'Simcard',
	CAN_BE_BLOCKED: '¿Puede ser bloqueado?',
	CAN_BE_BLOQUED_TOOLTIP: 'Esto indica si el rastreador se puede configurar para bloquear el vehículo',
	INFO_TRACKER: 'Información del último paquete',
	EVENT_NAME: 'Nombre del evento',
	SPEED: 'Velocidad',
	SERVER_TIME: 'Hora del servidor',
	GPS_TIME: 'Hora del GPS',
	IGNITION_ON: 'Estado de la ignición',
	VEHICLE_VOLTAGE: 'Voltaje del vehículo',
	BATTERY: 'Voltaje de la batería',
	ODOMETER: 'Odómetro',
	UNAVAILABLE: 'No disponible',
	ON: 'Ligada',
	OFF: 'Desligada',
	LONGITUDE: 'Longitude',
	LATITUDE: 'Latitude',

	// Encabezado

	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar filtros',
	TITLE: 'Rastreadores',
	DOWNLOAD_ALL: 'Descargar todos',
	ADD_USER: 'Agregar Rastreador',

	// Barra Lateral

	EDIT: 'Editar',
	VIEW: 'Ver',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	SELECTED_ITEMS: 'Elementos seleccionados',
	NO_ITEM_SELECTED: 'Ningún elemento seleccionado',
	EXPORT_SELECTED: 'Exportar elementos seleccionados',
	DELETE_SELECTED: 'Eliminar elementos seleccionados',
	EDIT_SELECTED: 'Editar elementos seleccionados',
	EDIT_TITLE: 'Editar rastreador',
	EDIT_INFO: 'Editar rastreadores',
	DELETE_INFO: 'Eliminar rastreadores',
	DOWNLOAD_INFO: 'Descargar rastreadores seleccionados',
	EDIT_MULTIPLE_INFO: 'Editar rastreadores seleccionados',
	DELETE_MULTIPLE_INFO: 'Eliminar rastreadores seleccionados',
	SELECTED_ITENS: '{{n}} elementos seleccionados',

	// Tabla

	OF: 'de',
	ROWS_PER_PAGE: 'Elementos por página',
	NO_TRACKERS_FOUND: 'No se encontraron rastreadores',

	// Modal

	EDIT_CONFIRMATION: '¿Estás seguro de que deseas editar este rastreador?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Estás seguro de que deseas editar los rastreadores seleccionados?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	DELETE_MODAL_TITLE: 'Eliminar rastreador',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro de que deseas eliminar el rastreador',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Estás seguro de que deseas eliminar {{n}} rastreadores',
	WARNING: 'Advertencia',
	TRACKER_STATUS_WARNING:
		'Si el estado del dispositivo se cambia a "En stock" y está asociado a un vehículo, el vehículo se desasociará del rastreador.',
	TRACKER_STATUS_WARNING_2: 'Esta acción no se puede deshacer. ¿Estás seguro de que deseas hacer esto?',

	// Permisos

	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',

	// Solicitudes

	CREATE_SUCCESS: 'Rastreador agregado exitosamente',
	CREATE_ERROR: 'Error al agregar rastreador',
	EDIT_SUCCESS: 'Rastreador actualizado exitosamente',
	EDIT_ERROR: 'Error al actualizar rastreador',
	DELETE_SUCCESS: 'Rastreador eliminado exitosamente',
	DELETE_ERROR: 'Error al eliminar rastreador',
	DOWNLOAD_ERROR: 'Error al descargar archivo',
	EDIT_MULTIPLE_SUCCESS: 'Rastreadores actualizados exitosamente',
	EDIT_MULTIPLE_ERROR: 'Error al actualizar rastreadores',

	// Tracker Status

	ACTIVE: 'Activo',
	INACTIVE: 'Inactivo',
	LOST: 'Perdido',
	DAMAGED: 'Dañado',
	STOCK: 'En stock',
	STATUS: 'Estado del dispositivo',

	SHOW_INVALID_ROWS: 'Mostrar filas inválidas',
	CLEAR_TABLE: 'Limpiar tabla',
	CREATING: 'Creando',
	DESCRIPTION_HEADER:
		'Aquí puedes crear rastreadores en granel al cargar un archivo XLSX con datos de los conductores o descargar la plantilla y completarla con los datos de los rastreadores que deseas crear. Puedes abrir el archivo XLSX con Excel u otro editor de hojas de cálculo.',
	DOWNLOAD_TEMPLATE: 'Descargar plantilla.',
	IMPORT: 'Importar',
	DROPZONE_TEXT: 'Arrastra y suelta el archivo XLSX aquí o haz clic para seleccionar.',
	REMOVE_ROW: 'Eliminar filas',
	ERROR: 'Error',
	SUCCESS: 'Éxito',
	VALIDS: 'Válidos',
	INVALIDS: 'Inválidos',
	MASSIVE_CREATE_SUCCESS: 'Rastreadores creados exitosamente, confira os detalhes no icone de resumo.',
	MASSIVE_CREATE_ERROR: 'Error al crear rastreadores',

	// Restore
	DELETED: 'Eliminado',
	SHOW_DELETED: 'Elementos eliminados',
	RESTORE_INFO: 'Restaurar rastreador',
	RESTORE_MODAL_TITLE: 'Restaurar rastreador',
	RESTORE_MODAL_DESCRIPTION: '¿Estás seguro de que deseas restaurar el rastreador: {{name}}',
	RESTORE_SUCCESS: 'Rastreador restaurado exitosamente',
	RESTORE_ERROR: 'Error al restaurar el rastreador',
	DELETED_AT: 'Eliminado en',
	DELETED_BY: 'Eliminado por',
	SATELLITES: 'Satélites',

	SEND_COMMAND: 'Enviar Comando',
	COMMAND_IDENTITY: 'Identidad del Comando',
	COMMAND_IDENTITY_INFO:
		'Esta es la identidad del comando que se enviará al rastreador, utilizado para generar el historial de comandos enviados.',
	DID_INFO: 'Este campo se llena automáticamente con el número del rastreador, no es necesario completarlo.',
	COMMAND_IDENTITY_REQUIRED: 'El campo de identidad del comando es obligatorio',
	VALUE_REQUIRED: 'El campo es obligatorio',
	LAST_SENT_COMMAND: 'Último comando enviado:',
	[TrackerCommandStatus.Pending]: 'Pendiente',
	[TrackerCommandStatus.Canceled]: 'Cancelado',
	[TrackerCommandStatus.Queued]: 'En cola',
	[TrackerCommandStatus.Sent]: 'Enviado',
	[TrackerCommandStatus.Confirmed]: 'Confirmado',
	[TrackerCommandStatus.Scheduling]: 'Programando',
	LAST_SENT_COMMAND_SUCCESS: '¡Comando registrado con éxito!',
	LAST_SENT_COMMAND_SUCCESS_INFO:
		'El envío del comando puede tardar unos minutos, monitorea el estado del envío a continuación.',
	LAST_SENT_COMMAND_ERROR: 'Error al enviar el comando',
	LAST_SENT_COMMAND_ERROR_INFO: 'Hubo un error al enviar el comando, verifica la disponibilidad del rastreador.',
	RESPONSE: 'Respuesta del rastreador:',
	LAST_SENT_COMMANDS: 'Últimos comandos enviados.',
	LAST_SENT_COMMANDS_DESCRIPTION:
		'Aquí puedes ver los últimos comandos enviados al rastreador, haz clic para ver los detalles.',
	SEND_COMMAND_TO: 'Enviar comando a'
};

export default locale;
