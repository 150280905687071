import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';

import { TScheduledHistoryCommand } from 'app/main/commands/historyCommands/types';
import { TrackerCommandStatus } from 'app/types/tracker-command-status.types';
import { TTracker } from 'src/app/main/map/types';
import { RootState } from '..';
import { apiSlice } from './apiSlice';
import { TGetHistoryProps } from './types';

type TBlockCommandHistory = TScheduledHistoryCommand & {
	scheduledCommandHistory: TScheduledHistoryCommand;
};

const sorter = (a: TTracker, b: TTracker) => {
	const noDate = dayjs().subtract(10, 'days');
	const dateA = _.get(a, 'packet.SERVER_TIME', noDate);
	const dateB = _.get(b, 'packet.SERVER_TIME', noDate);
	return dayjs(dateB).toDate().getTime() - dayjs(dateA).toDate().getTime();
};

const setPending = (trackerDID: number, isBlock: boolean) =>
	realtimeSlice.util.updateQueryData('checkBlockCommandStatus', trackerDID, (draft) => {
		const newScheduledCommand = {
			command: {
				commandName: isBlock ? 'Bloqueio' : 'Desbloqueio'
			},
			scheduledCommandHistory: {
				history: [
					{
						status: TrackerCommandStatus.Scheduling,
						executedAt: new Date()
					}
				]
			}
		};

		Object.assign(draft, newScheduledCommand);
	});

export const realtimeAdapter = createEntityAdapter({
	selectId: (data: TTracker) => data._id,
	sortComparer: sorter
});
const initialState = realtimeAdapter.getInitialState();

export const realtimeSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getRealtime: builder.query<EntityState<TTracker>, string>({
			query: (filterId = '') => (filterId ? `/tracking/realtime?filterId=${filterId}` : '/tracking/realtime'),
			transformResponse: (data: TTracker[]) => {
				return realtimeAdapter.setAll(initialState, data);
			},
			providesTags: ['Realtime'],
			async onQueryStarted(filterId, { dispatch, getState, queryFulfilled }) {
				const selectedTrackerId = (getState() as RootState).map.selectedTrackerId;
				if (!selectedTrackerId) return;
				const { data } = await queryFulfilled;
				const selectedTracker = data[0];
				if (!selectedTracker) return;
				dispatch(
					realtimeSlice.util.updateQueryData('getRealtime', filterId, (draft) => {
						const clonedTracker = _.cloneDeep(selectedTracker);
						Object.assign(draft, realtimeAdapter.upsertOne(draft, clonedTracker));
					})
				);
			}
		}),
		getRealtimeByDID: builder.query<TTracker[], { did: number; filterId: string }>({
			query: ({ did }) => ({
				url: `/tracking/realtime/?did=${did}`
			}),
			async onQueryStarted({ filterId }, { dispatch, queryFulfilled }) {
				const { data } = await queryFulfilled;
				if (!data) return;

				dispatch(
					realtimeSlice.util.updateQueryData('getRealtime', filterId, (draft) => {
						const newTracker = data[0];
						if (!newTracker) return;
						const clonedTracker = _.cloneDeep(newTracker);
						Object.assign(draft, realtimeAdapter.upsertOne(draft, clonedTracker));
					})
				);
			},
			providesTags: (___, __, { did }) => [{ type: 'Realtime', id: did }]
		}),
		getRealtimeWithFilters: builder.query<TTracker[], string>({
			query: (filter = '') => `/tracking/realtime?${filter}`,
			providesTags: ['RealtimeWithFilters']
		}),
		getHistory: builder.query({
			query: ({ trackerId, vehicleId, startDate, endDate }: TGetHistoryProps) =>
				`/tracking/history/vehicle/${vehicleId}/tracker/${trackerId}?start=${startDate}&end=${endDate}&limit=0`
		}),
		blockTracker: builder.mutation<void, number>({
			query: (trackerDID) => ({
				url: `/tracker/block/`,
				method: 'POST',
				body: {
					did: trackerDID,
					block: true
				}
			}),
			onQueryStarted(trackerDID, { dispatch, queryFulfilled }) {
				const patch = dispatch(setPending(trackerDID, true));
				queryFulfilled.catch(patch.undo);
			}
		}),
		unblockTracker: builder.mutation<void, number>({
			query: (trackerDID) => ({
				url: `/tracker/block/`,
				method: 'POST',
				body: {
					did: trackerDID,
					block: false
				}
			}),
			onQueryStarted(trackerDID, { dispatch, queryFulfilled }) {
				const patch = dispatch(setPending(trackerDID, false));
				queryFulfilled.catch(patch.undo);
			}
		}),
		checkBlockCommandStatus: builder.query<TBlockCommandHistory, number>({
			query: (trackerDID) => `/block-command-history/last/${trackerDID}`,
			providesTags: (__, ___, trackerDID) => [{ type: 'BlockCommandHistory', id: trackerDID }]
		})
	})
});

export const {
	selectAll: selectAllTrackers,
	selectIds: selectTrackersIds,
	selectById: selectTrackerById
} = realtimeAdapter.getSelectors();

export const {
	useGetRealtimeQuery,
	useLazyGetHistoryQuery,
	useBlockTrackerMutation,
	useUnblockTrackerMutation,
	useCheckBlockCommandStatusQuery,
	useGetRealtimeWithFiltersQuery,
	useGetRealtimeByDIDQuery
} = realtimeSlice;
