const locale = {
	// Header

	DOWNLOAD_ERROR: 'Error downloading file, please try again.',
	DOWNLOAD_ALL: 'Download All',
	DOWNLOAD_SELECTED: 'Download Selected',
	SHOW_FILTERS: 'Show Filters',
	RULER_COMMAND_NAME: 'Rule Command Name',
	CONFIG_TABLE: 'Configure table',

	// Colunas

	RULE_NAME: 'Rule name',
	SCHEDULED_COMMAND_NAME: 'Scheduled Command Name',
	NEXT_EXECUTION: 'Next Execution',
	TARGET_COMPANIES: 'Target Companies',
	TARGET_FLEETS: 'Target Fleets',
	TARGET_VEHICLES: 'Target Vehicles',
	TARGET_TRACKERS: 'Target Trackers',
	CREATED_AT: 'Created At',
	CREATED_BY: 'Created By',
	UPDATED_AT: 'Updated At',
	STATUS: 'Status',
	SCHEDULED_AT: 'Scheduled At',
	EXECUTED_AT: 'Executed At',
	// Tabela

	ROWS_PER_PAGE: 'Rows per Page',
	OF: 'of',

	// Permissões

	PAGE_NOT_ALLOWED: 'You are not allowed to view this page.',
	MINIMAL_START: 'Request the Administrator to add the following permissions to your profile:',
	MINIMAL_END: 'Then log out and log back into the system.',

	// Cabeçalho

	SCHEDULE: 'Schedule',
	TITLE: 'Scheduled Commands',

	// Status

	PENDING: 'Pending',
	QUEUED: 'Queued',
	'RE-QUEUED': 'Re-queued',
	SENT: 'Sent',
	CONFIRMED: 'Confirmed',
	CANCELED: 'Canceled',
	ERROR: 'Error',
	'EXPIRED:': 'Expired',

	// Comum

	SCHEDULED_FOR: 'Scheduled For',
	NOT_SCHEDULED: 'Immediately',
	SCHEDULED_COMMAND: 'Scheduled Command',
	EDIT: 'Edit',
	DELETE: 'Delete',
	SUMMARY: 'Summary',
	COMPANY: 'Company',
	FLEET: 'Fleet',
	VEHICLE: 'Vehicle',
	TRACKER: 'Tracker',
	SEND_TO: 'Send To',
	CLICK_HERE_TO_EXPAND: 'Click here to expand',
	SUMMARY_ERROR: 'Error loading summary',

	//Filters
	SELECT_FILTERS: 'Select filters',
	FILTERS: 'Filters',
	CREATED_BY_ME: 'Scheduled by me',
	CLEAR_FILTERS: 'Clear filters',
	FROM_STATUS: 'From State',
	TO_STATUS: 'To State',
	START_DATE: 'Start Date',
	END_DATE: 'End Date',
	RULES: "Rules",
	RULES_SELECTED: "{{n}} selected rules",
	SEARCH: "Search",

	SCHEDULED_HISTORY: 'Scheduled command history',
	RULER_HISTORY: 'Rule command history'
};

export default locale;
