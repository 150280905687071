import { CloseOutlined, SaveOutlined, SendOutlined } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	FormLabel,
	IconButton,
	List,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	SelectChangeEvent,
	Stack,
	Typography
} from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import AppButton from 'app/shared-components/Buttons/AppButton';
import SelectButton from 'app/shared-components/Buttons/SelectButton';
import { useGetCompaniesQuery, useGetFleetsQuery, useGetVehiclesQuery } from 'app/store/api/apiSlice';
import {
	selectAllSettings,
	selectSettingsEntities,
	selectSettingsIds,
	useDeleteRealtimeSettingsMutation,
	useGetRealtimeSettingsQuery,
	usePostRealtimeSettingsMutation
} from 'app/store/api/settingsSlice';
import { IRealtimeSettings, IRealtimeSettingsPayload } from 'app/store/api/types';
import { useUpdateUserFilterMutation } from 'app/store/api/userSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import {
	initialState,
	selectFilters,
	selectSelectedUserFilter,
	setAllFilters,
	setIgnitionOn,
	setIsDefaultFilter,
	setNewFilterName,
	setSelectedFilter,
	setSelectedUserFilter,
	setVelocityOperator,
	setVelocityValue
} from 'app/store/map/advanceFilterSlice';
import { setFiltersOpen } from 'app/store/map/mapSlice';
import { selectUser, updateUserDefaultFilter } from 'app/store/user/userSlice';
import { TCompany, TFilters, TFleet, TValLab, TVehicle } from '../types';
import MyFilterItem from './MyFiltersItem';
import SaveFilterPopover from './SaveFilterPopover';

const IGNITIONS = {
	on: true,
	off: false
};

function filterOrDefault(data: IRealtimeSettings | boolean, allData: TValLab[], targetKey: string): TValLab[] | [] {
	if (!data) return [];
	const confirmedData: string[] | boolean = _.get(data, ['filters', targetKey], false);
	if (typeof confirmedData === 'boolean') {
		return [];
	}
	return _.filter(allData, (p) => confirmedData.includes(p.value));
}
const parseFilters = (filters: TFilters, name: string): IRealtimeSettingsPayload => ({
	name,
	ttl: !name,
	filters: {
		company: _.map(filters.company, (p) => _.get(p, '_id')),
		fleet: _.map(filters.fleet, (p) => _.get(p, '_id')),
		vehicle: _.map(filters.vehicle, (p) => _.get(p, '_id')),
		state: _.map(filters.state, (p) => _.get(p, 'value')),
		...(_.isEmpty(filters.vehicleType)
			? {}
			: { vehicleType: _.map(filters.vehicleType, (p) => _.get(p, 'value')) }),
		...(_.isEmpty(filters.ignitionOn) ? {} : { ignitionOn: IGNITIONS[filters.ignitionOn] }),
		...(filters.velocity.value
			? { velocity: { operator: _.get(filters, 'velocity.operator'), value: _.get(filters, 'velocity.value') } }
			: {})
	}
});

const emptyObj = {};
const emptyArr = [];

function FiltersPanel() {
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const { t } = useTranslation('mapPage');
	const { data: companies, isLoading: isCompaniesLoading } = useGetCompaniesQuery('limit=0');
	const { data: fleets, isLoading: isFleetsLoading } = useGetFleetsQuery('limit=0');
	const { data: vehicles, isLoading: isVehiclesLoading } = useGetVehiclesQuery('limit=0');
	const { settingsEntities, settingsIds, allSettings } = useGetRealtimeSettingsQuery('', {
		selectFromResult: (res) => ({
			allSettings: !res.data ? emptyArr : selectAllSettings(res.data),
			settingsIds: !res.data ? emptyArr : selectSettingsIds(res.data),
			settingsEntities: !res.data ? emptyObj : selectSettingsEntities(res.data),
			loading: res.isLoading
		})
	});
	const [postSettings, { isLoading: isPostLoading }] = usePostRealtimeSettingsMutation();
	const [deleteSettings, { isLoading: isDeleteLoading }] = useDeleteRealtimeSettingsMutation();
	const [updateUserFilter] = useUpdateUserFilterMutation();
	const filterValue: TFilters = useSelector(selectFilters);
	const selectedUserFilter = useSelector(selectSelectedUserFilter);
	const currentUser = useSelector(selectUser);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const filterId = searchParams.get('filterId') || '';
	const noFilterSelected = _.isEqual(filterValue, initialState.selectedFilters);
	const isApplyBlocked = noFilterSelected || Boolean(selectedUserFilter) || isPostLoading;
	const popOpen = Boolean(anchorEl);

	const VEHICLE_TYPE_DATA = useMemo(
		() => [
			{ label: t('CAR'), value: '633832efc547f5b420a17486' },
			{ label: t('BOAT'), value: '633834d4c547f5b420a17498' },
			{ label: t('BIKE'), value: '633834bdc547f5b420a17495' },
			{ label: t('TRUCK'), value: '63383310c547f5b420a17489' },
			{ label: t('MOTORBIKE'), value: '633833ffc547f5b420a1748f' },
			{ label: t('OTHERS'), value: '63383536c547f5b420a1749b' }
		],
		[t]
	);
	const getAllFilters = useCallback(
		(selected: string) => ({
			company: _.get(settingsEntities, [selected, 'filters', 'company'], initialState.selectedFilters.company),
			fleet: _.get(settingsEntities, [selected, 'filters', 'fleet'], initialState.selectedFilters.fleet),
			vehicle: _.get(settingsEntities, [selected, 'filters', 'vehicle'], initialState.selectedFilters.vehicle),
			vehicleType: filterOrDefault(_.get(settingsEntities, [selected], false), VEHICLE_TYPE_DATA, 'vehicleType'),
			ignitionOn: _.get(
				settingsEntities,
				[selected, 'filters', 'ignitionOn'],
				initialState.selectedFilters.ignitionOn
			),
			velocity: _.get(settingsEntities, [selected, 'filters', 'velocity'], initialState.selectedFilters.velocity),
			isDefaultFilter: currentUser.defaultFilter === selected
		}),
		[VEHICLE_TYPE_DATA, settingsEntities, currentUser]
	);
	const VELOCITY_DATA = [
		{ label: t('EQUALS'), value: '==' },
		{ label: t('GREATER_THAN'), value: '>' },
		{ label: t('LESS_THAN'), value: '<' }
	];

	const IGNITION_DATA = [
		{ label: t('ON'), value: 'on' },
		{ label: t('OFF'), value: 'off' }
	];

	const handleCloseFilter = () => {
		dispatch(setFiltersOpen(false));
	};

	const handleFiltersChange =
		<T, _>(filter: string) =>
		(_ev: any, value: T[]) => {
			dispatch(setSelectedFilter({ filter, value }));
		};

	const handleVelocityValue = (ev: ChangeEvent<HTMLInputElement>) => {
		dispatch(setVelocityValue(ev.target.value));
	};

	const handleVelocityOperator = (ev: SelectChangeEvent) => {
		dispatch(setVelocityOperator(ev.target.value));
	};
	const handleSaveClick = (ev: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(ev.currentTarget);
	};

	const deleteFilter = async (id: string) => {
		try {
			await deleteSettings(id).unwrap();
			if (currentUser.defaultFilter === id) {
				await updateUserFilter({ realtimeDefaultFilterId: null, id: currentUser.data.userId });
				dispatch(updateUserDefaultFilter(null));
			}
			dispatch(setSelectedUserFilter(''));
			if (filterId === id) {
				setSearchParams('');
			}
			dispatch(setAllFilters(initialState.selectedFilters));
			dispatch(
				showMessage({
					message: t('SUCCESS_DELETE_FILTER'),
					variant: 'success'
				})
			);
		} catch {
			dispatch(
				showMessage({
					message: t('ERROR_DELETE_FILTER'),
					variant: 'error'
				})
			);
		}
	};

	const handleFilterSelected = (id: string) => {
		const isUncheck = id === selectedUserFilter;
		if (isUncheck) {
			dispatch(setSelectedUserFilter(''));
			dispatch(setAllFilters(initialState.selectedFilters));
			setSearchParams('');
		} else {
			const allSelectedFilters = getAllFilters(id);
			dispatch(setSelectedUserFilter(id));
			dispatch(setAllFilters(allSelectedFilters));
			setSearchParams({ filterId: id });
			dispatch(
				showMessage({
					message: t('SUCCESS_APPLY_FILTER'),
					variant: 'success'
				})
			);
		}
	};

	const handleIgnitionChange = (ev: ChangeEvent<HTMLInputElement>) => {
		dispatch(setIgnitionOn(ev.target.checked ? ev.target.name : ''));
	};

	const handleMakeDefault = async (ev: ChangeEvent<HTMLInputElement>) => {
		if (!selectedUserFilter) {
			// the user is creating a new filter
			dispatch(setIsDefaultFilter(ev.target.checked));
			return;
		}

		const data = {
			realtimeDefaultFilterId: ev.target.checked ? selectedUserFilter : null,
			id: currentUser.data.userId
		};

		try {
			dispatch(setIsDefaultFilter(ev.target.checked));
			await updateUserFilter(data).unwrap();
			dispatch(updateUserDefaultFilter(data.realtimeDefaultFilterId));
			dispatch(
				showMessage({
					message: ev.target.checked ? t('SUCCESS_DEFAULT_FILTER') : t('SUCCESS_DEFAULT_FILTER_UNSET'),
					variant: 'success'
				})
			);
		} catch {
			dispatch(
				showMessage({
					message: t('ERROR_DEFAULT_FILTER'),
					variant: 'error'
				})
			);
		}
	};
	const handleSaveClose = () => {
		setAnchorEl(null);
	};

	const saveFilters = async (newFilterName: string) => {
		handleSaveClose();
		try {
			const parsed = parseFilters(filterValue, newFilterName);
			const res = await postSettings(parsed).unwrap();
			const filterId = res._id;

			if (filterValue.isDefaultFilter) {
				await updateUserFilter({ realtimeDefaultFilterId: filterId, id: currentUser.data.userId }).unwrap();
				dispatch(updateUserDefaultFilter(filterId));
			}
			setSearchParams({ filterId });

			dispatch(
				showMessage({
					message: t('SUCCESS_SAVE_FILTER'),
					variant: 'success'
				})
			);
			dispatch(setNewFilterName(''));
			dispatch(setSelectedUserFilter(filterId));
		} catch (err) {
			dispatch(
				showMessage({
					message: t('ERROR_SAVE_FILTER'),
					variant: 'error'
				})
			);
		}
	};

	const handleClearFilter = () => {
		dispatch(setSelectedUserFilter(''));
		dispatch(setAllFilters(initialState.selectedFilters));
		setSearchParams('');
	};

	useEffect(() => {
		// checking if the filterId searchParam was set to the user selected filter
		// this will fill the form values
		if (_.isEmpty(settingsIds)) return;
		const filterId = searchParams.get('filterId') || '';
		if (_.isEmpty(selectedUserFilter) && settingsIds.includes(filterId)) {
			const allSelectedFilters = getAllFilters(filterId);
			dispatch(setSelectedUserFilter(filterId));
			dispatch(setAllFilters(allSelectedFilters));
		}
	}, [searchParams, selectedUserFilter, settingsIds, dispatch, getAllFilters]);

	useEffect(() => {
		if (!currentUser.defaultFilter) return;
		const trackerId = searchParams.get('trackerId');
		if (trackerId) return;
		setSearchParams({ filterId: currentUser.defaultFilter });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					paddingTop: 1
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						paddingRight: 2
					}}
				>
					<IconButton size="small" onClick={handleCloseFilter}>
						<CloseOutlined />
					</IconButton>
				</Box>
				<Stack
					direction="column"
					spacing={3}
					justifyContent="flex-start"
					alignItems="flex-start"
					sx={{
						paddingX: 2,
						paddingBottom: 2
					}}
				>
					<Typography variant="h6">{t('ADVANCED_FILTERS')}</Typography>
					<Typography variant="subtitle2">{t('MY_FILTERS')}</Typography>
					<Paper
						square
						variant="outlined"
						sx={{
							width: '100%'
						}}
					>
						<List
							sx={{
								maxHeight: '266px',
								overflowY: 'auto'
							}}
						>
							{allSettings.length ? (
								allSettings.map((item) => (
									<MyFilterItem
										item={item}
										itemChecked={selectedUserFilter}
										isDeleteLoading={isDeleteLoading}
										handleClick={handleFilterSelected}
										handleDelete={deleteFilter}
										key={item._id}
									/>
								))
							) : (
								<Typography pl={1} fontStyle="italic">
									{t('NO_FILTERS')}
								</Typography>
							)}
						</List>
					</Paper>

					<SelectButton<TCompany>
						optionLabel="name"
						label={t('COMPANIES')}
						loading={isCompaniesLoading}
						options={_.get(companies, 'docs', [])}
						value={filterValue.company}
						handleChange={handleFiltersChange('company')}
						isDisabled={Boolean(selectedUserFilter)}
						limitText={(n) => t('SELECTED_COMPANIES', { n })}
						fullWidth
					/>
					<SelectButton<TFleet>
						optionLabel="name"
						label={t('FLEETS')}
						loading={isFleetsLoading}
						options={_.get(fleets, 'docs', [])}
						value={filterValue.fleet}
						handleChange={handleFiltersChange('fleet')}
						limitText={(n) => t('SELECTED_FLEETS', { n })}
						isDisabled={Boolean(selectedUserFilter)}
						optionKey="_id"
						fullWidth
					/>
					<SelectButton<TVehicle>
						optionLabel="name"
						label={t('VEHICLES')}
						loading={isVehiclesLoading}
						options={_.get(vehicles, 'docs', [])}
						value={filterValue.vehicle}
						handleChange={handleFiltersChange('vehicle')}
						limitText={(n) => t('SELECTED_VEHICLES', { n })}
						isDisabled={Boolean(selectedUserFilter)}
						optionKey="_id"
						isVirtualized
						fullWidth
					/>

					{/*
					Disabled until the backend is ready

					<SelectButton
						optionLabel="label"
						label={t('VEHICLE_TYPE')}
						options={VEHICLE_TYPE_DATA}
						value={filterValue.vehicleType}
						handleChange={handleFiltersChange('vehicleType')}
						limitText={(n) => t('SELECTED_VEHICLE_TYPES', { n })}
						isDisabled={Boolean(selectedUserFilter)}
						fullWidth
					/> */}
					<Stack spacing={1} direction="column">
						<Typography variant="caption">{t('SPEED')}</Typography>
						<Stack direction="row" spacing={2} alignItems="center">
							<Select
								variant="outlined"
								defaultValue="=="
								size="small"
								sx={{ minWidth: '120px' }}
								value={filterValue.velocity.operator}
								onChange={handleVelocityOperator}
								disabled={Boolean(selectedUserFilter)}
							>
								{VELOCITY_DATA.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										{item.label}
									</MenuItem>
								))}
							</Select>
							<OutlinedInput
								placeholder="valor"
								size="small"
								value={filterValue.velocity.value}
								onChange={handleVelocityValue}
								disabled={Boolean(selectedUserFilter)}
							/>
						</Stack>
					</Stack>
					<FormGroup>
						<FormLabel component="legend">{t('IGNITION_ON')}</FormLabel>
						{IGNITION_DATA.map((item) => (
							<FormControlLabel
								control={<Checkbox />}
								label={item.label}
								key={item.label}
								checked={item.value === filterValue.ignitionOn}
								onChange={handleIgnitionChange}
								name={item.value}
								disabled={Boolean(selectedUserFilter)}
							/>
						))}
					</FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								onChange={handleMakeDefault}
								checked={filterValue.isDefaultFilter}
								disabled={!selectedUserFilter}
							/>
						}
						label={t('MAKE_DEFAULT')}
						labelPlacement="start"
					/>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							width: '100%'
						}}
					>
						<AppButton
							variant="contained"
							color="secondary"
							endIcon={<SaveOutlined />}
							onClick={handleSaveClick}
							loading={isPostLoading}
							disabled={isApplyBlocked}
							sx={{
								marginRight: 2
							}}
						>
							{t('SAVE')}
						</AppButton>
						<AppButton
							variant="contained"
							color="primary"
							endIcon={<SendOutlined />}
							onClick={() => saveFilters('')}
							disabled={isApplyBlocked}
							loading={isPostLoading}
						>
							{t('APPLY')}
						</AppButton>
						<AppButton
							variant="outlined"
							color="error"
							onClick={handleClearFilter}
							sx={{
								marginLeft: 'auto'
							}}
							disabled={_.isEqual(filterValue, initialState.selectedFilters)}
						>
							{t('CLEAR')}
						</AppButton>
					</Box>
				</Stack>
			</Box>
			<SaveFilterPopover handleClose={handleSaveClose} handleSave={saveFilters} isOpen={popOpen} ref={anchorEl} />
		</>
	);
}
const memoFilterPanel = memo(FiltersPanel);
export default memoFilterPanel;
