export default {
	COMPANY: 'Empresa',
	TRACKER: 'Rastreador',
	TRACKER_TYPE: 'Tipo de Rastreador',
	STATUS: 'Último Estado',
	CREATED_AT: 'Enviado en',
	SENDED_BY: 'Enviado por',
	UPDATED_AT: 'Última Actualización',
	LAST_STATUS_UPDATED: 'Última actualización de estado',
	TITLE: 'Informe de Bloqueo/Desbloqueo',
	VIEW_SCHEDULED_COMMAND: 'Ver Comando Programado',
	VIEW_HISTORY_COMMAND: 'Ver Historial de Comandos',
	COMMAND_TIMELINE: 'Línea de Tiempo del Comando',
	COMMAND: 'Comando',
	NO_TIMELINE: 'No se encontró línea de tiempo para este comando',
	CONFIG_TABLE: 'Configurar tabla',
	SHOW_FILTERS: 'Mostrar filtros',
	CLEAR_FILTERS: 'Borrar filtros',
	NO_DATA: 'No se encontraron datos',
	NO_FILTERS: 'No se aplicaron filtros',
	NO_FILTERS_SUBTITLE: 'Seleccione los filtros que desea aplicar para ver los resultados',
	CREATED_BY: 'Creado por',
	START_DATE: 'Fecha de inicio',
	END_DATE: 'Fecha de finalización',
	SEARCH: 'Buscar',
	ERROR_SELECTED_COMPANY: 'Seleccione al menos un rastreador o uno enviado por',

	PENDING: 'Pendiente',
	QUEUED: 'Programado',
	'RE-QUEUED': 'Reencolado',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	CANCELED: 'Cancelado',
	ERROR: 'Error',
	EXPIRED: 'Expirado',
	SCHEDULING: 'Programando'
};
