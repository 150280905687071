const enItem = {
	ROWS_PER_PAGE: 'Items per page',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	OF: 'of',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	TITLE: 'Maintenances',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	ERROR_PERMISSION: 'You do not have permission to access this page, please contact the system administrator',
	ERROR_GETTING_MAINTENANCES: 'Error getting maintenances, please contact the system administrator',
	ERROR_RESPONSE_404: 'Error: Data not found, please contact the system administrator',
	ERROR_INTERNAL_SERVER_ERROR: 'Error: Internal server error, please contact the system administrator',
	ERROR_NETWORK: 'Error: Network error, please check your connection and try again',
	ADD_MAINTENANCE: 'Add Maintenance',
	NO_DATA: 'No matching items were found',
	NO_RESULTS: 'No results found',
	NO_PERMISSION_list: "You don't have permission to view the maintenance list",
	NO_PERMISSION_new: "You don't have permission to add maintenances",
	NO_PERMISSION_edit: "You don't have permission to edit maintenances",
	NO_PERMISSION_view: "You don't have permission to view maintenances",
	NO_PERMISSION_delete: "You don't have permission to delete maintenances",

	MAINTENANCE_TYPE: 'Maintenance Type',
	STATUS: 'Status',
	NOTIFICATIONS: 'Notifications',
	SCHEDULE: 'Schedule',
	OPTIONAL: 'Optional',
	EDIT_MAINTENANCE: 'Edit Maintenance',
	SCHEDULE_MAINTENANCE: 'Schedule a new maintenance',

	NO_MAINTENANCE_FOUND: 'No maintenance found.',
	SELECT_ITEM: 'Select an item',
	PREVENTIVE: 'Preventive',
	CORRECTIVE: 'Corrective',
	UNPLANNED: 'Unplanned',
	BY_DATE: 'By date',
	BY_MILEAGE: 'By mileage',
	MILEAGE_LABEL: 'Type the mileage',
	DEADLINE_LABEL: 'Select limit date',
	KEEP_FREQUENCY_NEXT: 'This maintenance will happen regularly?',
	MONTHLY: 'Monthly',
	DAILY: 'Daily',
	WEEKLY: 'Weekly',
	CONTINUE: 'Continue',
	BACK: 'Back',
	LIMIT_TYPE: 'Limit type',

	MAINTENANCE_STATUS: 'Maintenance Status',
	OPENED: 'Opened',
	EXECUTED: 'Executed',
	MAINTENANCE_VALUE: 'Maintenance price',
	ODOMETER: 'Odometer',
	EXECUTION_DATE: 'Execution date',

	WARNING_BY: 'Warning by',
	DATE: 'Date',
	MILEAGE: 'Mileage',
	WARNING_DATE: 'Select the warning date',
	NOTIFY_BY: 'Notify by',
	APP: 'In app notifications',
	WHATSAPP: 'Whatsapp',
	EMAIL: 'Email',
	PHONE: 'Phone',

	EXECUTION_LOCATION: 'Execution location',
	EXECUTION_LOCATION_PLACEHOLDER: 'Enter the execution location',
	EXECUTION_RESPONSIBLE: 'Execution responsible',
	EXECUTION_RESPONSIBLE_PLACEHOLDER: 'Enter the name of the responsible by the maintenance execution',
	INVOICE_LABEL: 'Invoice',
	INVOICE_DROP: 'Click or drop the invoice here.',
	SELECT_MORE_FILES: 'Select more files',
	REMOVE: 'Remove',
	NOTIFICATION_KM: 'Enter the KM',
	ITEM: 'Item',
	TYPE: 'Type',
	LOCATION: 'Location',
	PRICE: 'Price',
	RESPONSIBLE: 'Responsible',
	FREQUENCY: 'Frequency',
	OPTIONALS: 'Optional',
	ITEM_IMAGE: 'Attach Item Image (Optional)',
	OBSERVATION_LABEL: 'Observation (Optional)',
	NAME: 'Name',
	TITLE_ITEM: 'Maintenance Item',
	ADD_MAINTENANCE_ITEM: 'Add Maintenance Item',
	CURRENT_ODOMETER: 'Current Odometer',
	CONFIRM: 'Confirm',
	MAINTENANCE_REPORT: 'Maintenance Report',
	EXPORT_DATA: 'Export Data',
	MAINTENANCE_TOTAL_EXPENSES: 'Total spent on maintenance',
	SCHEDULE_MAINTENANCE_EXPENSES_FORECAST: 'Forecasting expenditure on scheduled maintenance',
	MAINTENANCE_ITEMS_RANKING: 'Ranking of items with the most maintenance',
	MAINTENANCE_FLEET_RANKING: 'Ranking of fleets with the most maintenance',
	FLEET_PERIOD_MEDIAN: 'Inter-fleet period average',
	YEARLY_MEDIAN: 'Last year average',
	FOUR_WEEKS_MEDIAN: 'Média das últimas 4 semanas',
	NO_CHART_DATA: 'No data found',
	SELECT: 'Select',
	period: 'Period',
	FROM: 'From',
	TO: 'To',
	GET_REPORT_ERROR: 'Error on get report',
	SCHEDULED_MAINTENANCES: 'Schedule Maintenances',
	EXECUTED_MAINTENANCES: 'Executed Maintenances',
	SELECT_MAINTENANCE_TARGET:
		'Select a vehicle(s) or fleet(s) to register the maintenance, if you select a group of vehicles or a group of fleets, the maintenance will be registered for all vehicles in the group.',
	VEHICLES: 'Vehicles',
	FLEETS: 'Fleets',
	COMPANIES: 'Companies',
	FLEETS_SELECTED: '{{n}} fleets selected',
	VEHICLES_SELECTED: '{{n}} vehicles selected',
	COMPANY: 'Company',
	EXECUTE: 'Execute',
	VIEW: 'View',
	MAINTENANCE: 'Maintenance',
	EXECUTE_MAINTENANCE: 'Execute Maintenance',
	ODOMOTER_LIMIT: 'Odometer limit',
	LIMIT_DATE: 'Limit date',
	ATTACHMENTS: 'Attachments',
	MAINTENANCE_DETAILS: 'Maintenance Details',
	SELECTED_ITEMS: '{{n}} items selected',
	MAX_ITEM_ERROR: 'You can only select a maximum of 3 items',
	MIN_ITEM_ERROR: 'Select at least 1 item',
	MAINTENANCE_STATUS_LABEL: 'What is the maintenance status?',
	INFORMATIONS: 'Informations',
	MAINTENANCE_INFORMATIONS: 'Maintenance Informations',
	KEEP_FREQUENCY_TOOLTIP_TEXT:
		'When this option is checked, the next automatically generated maintenances will use the same limits',
	FREQUENCY_INTERVAL_TOOLTIP_TEXT: 'Select the interval in which the maintenance should be performed',
	MILEAGE_TOOLTIP_TEXT:
		'Enter the mileage limit until this maintenance is performed, for example, if you need the maintenance to be performed every 1000 km, enter 1000.',
	DEADLINE_DATE_TOOLTIP_TEXT: 'Select the deadline date for this maintenance',
	MONTHS: 'Months',
	DAYS: 'Days',
	YEARS: 'Years',
	WEEKS: 'Weeks',
	NOTIFICATION_INTERVAL_INFO: '{{n}} {{t}} before {{v}}',
	NOTIFICATION_MILEAGE_INFO: 'The vehicle to run {{n}} km from the current mileage',
	NOTIFICATION_INFO: 'You will be notified when:',
	OR: 'Or \n {{n}}. Whichever comes first.',
	LIMIT_TYPE_MISSING_ERROR: 'You must select at least one limit type',
	DEADLINE_DATE_REQUIRED: 'Deadline date is required',
	DEADLINE_MILEAGE_REQUIRED: 'Deadline mileage is required',
	FREQUENCY_REQUIRED: 'Select the frequency of the maintenance',
	SELECT_USERS: 'Select users',
	NOTIFICATION_TYPE_MISSING_ERROR: 'You must select at least one notification type',
	EMAIL_REQUIRED: 'Email is required',
	PHONE_REQUIRED: 'Phone is required',
	USER_REQUIRED: 'User is required',
	SELECTED_USERS: '{{n}} selected',
	PERIOD: 'Period',
	COMPANY_REQUIRED: 'Company is required',
	VEHICLE_REQUIRED: 'Vehicle is required',
	FLEET_REQUIRED: 'Fleet is required',
	EXECUTION_DATE_REQUIRED: 'Execution date is required',
	NOTIFICATION_PERIOD_TOOLTIP_TEXT:
		'Select the period before the date deadline in which the notification should be sent',
	NOTIFICATION_MILEAGE_TOOLTIP_TEXT: 'Enter the mileage limit in which the notification should be sent',
	PERIOD_AMOUNT: 'Period amount',
	CONCLUDE: 'Conclude',
	NO_LOCATION: 'No location informed',
	NO_RESPONSIBLE: 'No responsible informed',
	NO_ATTACHMENTS: 'No attachments found',
	ITEM_NAME_REQUIRED: 'Item is required',
	NO_USER_FOUND: 'No data found',
	CREATE_SUCCESS: 'Item successfully created',
	CREATE_ERROR: 'Error creating item',
	EDIT_SUCCESS: 'Item successfully edited',
	EDIT_ERROR: 'Error editing item',
	DELETE_SUCCESS: 'Item successfully deleted',
	DELETE_ERROR: 'Error deleting item',
	EDIT: 'Edit Item',
	DELETE_MODAL_TITLE: 'Delete item',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete the item?',
	DELETE_MODAL_CONFIRM: 'Yes, delete',
	DELETE_MODAL_CANCEL: 'Cancel',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MULTIPLE_INFO: 'Delete selected items'
};

export default enItem;
