import FuseUtils from '@fuse/utils';
import settingsConfig from 'app/configs/settingsConfig';
import AcademyAppConfig from 'app/main/academy/AcademyAppConfig';
import { AccessPeriodsConfig } from 'app/main/access-periods/AccessPeriodsConfig';

import ChangelogConfig from 'app/main/changelog/ChangelogConfig';
import DashboardMenuConfig from 'app/main/dashboard-menu/DashboardMenuConfig';
import ManageGeoFenceConfig from 'app/main/geo-fence/manage/ManageGeoFenceConfig';
import ShipsConfig from 'app/main/marine/ships/ShipsConfig';
import MonitoringDoneConfig from 'app/main/monitoring-done/MonitoringDoneConfig';
import MonitoringEventsConfig from 'app/main/monitoring-event/MonitoringEventsConfig';
import MonitoringSettingsConfig from 'app/main/monitoring-settings/MonitoringSettingsConfig';
import PanelsConfig from 'app/main/panels/PanelsConfig';
import RecoverPasswordConfig from 'app/main/recover-password/RecoverPasswordConfig';
import RegisterCommandsConfig from 'app/main/register-commands/RegisterCommandsConfig';
import ReportsConfig from 'app/main/reports/ReportsConfig';
import OperatorsReportConfig from 'app/main/reports/monitoring-operators/OperatorsReportConfig';
import ViewVehicleConfig from 'app/main/vehicle-view/ViewVehicleConfig';
import { Navigate } from 'react-router-dom';
import Error404Page from '../main/404/Error404Page';
import HistoryCommandsConfig from '../main/commands/historyCommands/HistoryCommandsConfig';
import ScheduleCommandsConfig from '../main/commands/scheduleCommands/ScheduleCommandsConfig';
import CompaniesConfig from '../main/companies/CompaniesConfig';
import ContactsConfig from '../main/contacts/ContactsConfig';
import DriversConfig from '../main/drivers/DriversConfig';
import FleetConfig from '../main/fleet/FleetConfig';

import FuelSupplyListConfig from 'app/main/fuel-supply/list/FuelSupplyListConfig';
import RegisterFuelSupplyConfig from 'app/main/fuel-supply/register/RegisterFuelSupplyConfig';
import { GeoFenceReportConfig } from 'app/main/geo-fence/report/GeoFenceReportConfig';
import IButtonConfig from '../main/ibutton/IbuttonConfig';
import MaintenanceConfig from '../main/maintenance-manager/MaintenanceConfig';
import MapConfig from '../main/map/MapConfig';
import MonitoringConfig from '../main/monitoring/MonitoringConfig';
import PermissionNotFoundConfig from '../main/permission-not-found/PermissionNotFoundConfig';
import PermissionsConfig from '../main/permissions/PermissionsConfig';
import ProfilesConfig from '../main/profiles/ProfilesConfig';
import RulerConfig from '../main/ruler/RulerConfig';
import RulerHistoryConfig from '../main/rulerHistory/rulerHistoryConfig';
import SessionsConfig from '../main/sessions/SessionsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SimcardsConfig from '../main/simcards/SimcardsConfig';
import TrackersConfig from '../main/trackers/TrackersConfig';
import UrlConfig from '../main/urls/UrlConfig';
import UsersConfig from '../main/users/UsersConfig';
import VehicleConfig from '../main/vehicle/VehicleConfig';

import PublicLinkConfig from 'app/main/public-link/PublicLinkConfig';

const routeConfigs = [
	SignOutConfig,
	SignInConfig,
	SignUpConfig,
	PermissionsConfig,
	CompaniesConfig,
	PermissionNotFoundConfig,
	ProfilesConfig,
	SimcardsConfig,
	UsersConfig,
	TrackersConfig,
	FleetConfig,
	DriversConfig,
	MapConfig,
	IButtonConfig,
	VehicleConfig,
	UrlConfig,
	SessionsConfig,
	ContactsConfig,
	RulerConfig,
	RecoverPasswordConfig,
	RegisterCommandsConfig,
	MonitoringConfig,
	ScheduleCommandsConfig,
	HistoryCommandsConfig,
	RulerHistoryConfig,
	ChangelogConfig,
	MonitoringEventsConfig,
	ShipsConfig,
	MonitoringSettingsConfig,
	MonitoringDoneConfig,
	ViewVehicleConfig,
	PanelsConfig,
	OperatorsReportConfig,
	...DashboardMenuConfig,
	...ReportsConfig,
	AcademyAppConfig,
	MaintenanceConfig,
	AcademyAppConfig,
	AccessPeriodsConfig,
	ManageGeoFenceConfig,
	GeoFenceReportConfig,
	FuelSupplyListConfig,
	RegisterFuelSupplyConfig,
	PublicLinkConfig
];

// Add auth: ["permission"] for protected routes

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
	{
		path: '/',
		element: <Navigate to="/realtime" />
	},
	{
		path: '*',
		element: <Error404Page />
	}
];

export type TRoutes = typeof routes;

export default routes;
